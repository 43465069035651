import { useFormik } from "formik"
import * as yup from "yup"
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import { Accordion, Card, Col, Form, Row } from "react-bootstrap"
import API from "../../../common/API"
import { toast } from "react-toastify"
import { CompanyI } from "../../../common/interface/Companies.interface"
import Pagination from "../../core/Pagination"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"

interface CompaniesFilterProps {
  setCompanies: Dispatch<SetStateAction<CompanyI[] | undefined>>
  toggleLoading: (value: boolean) => void
  isLoading: boolean
  children: ReactNode
}

// Validation
const validationSchema = yup.object({})

const CompaniesFilter: FC<CompaniesFilterProps> = ({
  setCompanies,
  toggleLoading,
  isLoading,
  children,
}) => {
  const { t } = useTranslation()

  const [countries, setCountries] = useState([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [quantity] = useState(10)

  const onPageChanged = (obj: number) => {
    setPage(obj)
  }

  const bindDataRemote = async obj => {
    try {
      toggleLoading(true)

      let filterFields = obj ? `name=${obj.name}&` : ""
      let queryString = `${filterFields}Quantity=${quantity}&Page=${page}`

      let requestAPI = await API.getAction("company/list?" + queryString)

      if (requestAPI.status === 200) {
        setTotalPages(requestAPI.data.quantityOfPages)
        setCompanies(requestAPI.data.response)
      } else {
        throw new Error("Oops, thats a error?")
      }
    } catch (error) {
      toast.error(`${error}`)
    } finally {
      toggleLoading(false)
    }
  }

  const bindDataCountries = async () => {
    try {
      let filterFields = `name=${""}&`
      let queryString = `${filterFields}Quantity=${255}&Page=${0}`

      let requestAPI = await API.getAction("Country/list?" + queryString)

      if (requestAPI.status === 200) {
        setCountries(requestAPI.data.response)
      } else {
        throw new Error("Oops, thats a error?")
      }
    } catch (error) {
      toast.error(`${error}`)
    }
  }
  useEffect(() => {
    bindDataCountries()
  }, [])
  useEffect(() => {
    bindDataRemote(null)
  }, [page])

  const formik = useFormik({
    initialValues: { name: "" },
    validationSchema,
    onSubmit: bindDataRemote,
  })

  return (
    <Row>
      <Col xs={12} md={4} lg={3}>
        <Accordion defaultActiveKey={"0"} className="mb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header style={{ zIndex: "1" }}>
              <h4 className="text-primary">
                {isLoading ? <Skeleton width={100} /> : t("Filter")}
              </h4>
            </Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col xs={12}>
                    <label>
                      {isLoading ? <Skeleton width={80} /> : t("Search")}
                    </label>
                    {isLoading ? (
                      <Skeleton height={38} />
                    ) : (
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                      />
                    )}
                  </Col>
                  <Col xs={12} className="mt-3">
                    <label>
                      {isLoading ? <Skeleton width={80} /> : t("Countries")}
                    </label>
                    {isLoading ? (
                      <Skeleton count={4} />
                    ) : (
                      <select
                        className="form-select w-full"
                        name="contries"
                        onChange={e => {
                          /* props.updateField(model.name, e.target.value); */
                        }}
                      >
                        <option defaultValue={""} value="" disabled>
                          -
                        </option>
                        {countries.length > 0
                          ? countries.map((item: { name: string }, index) => {
                              return (
                                <option key={index} value={item.name}>
                                  {item.name}
                                </option>
                              )
                            })
                          : null}
                      </select>
                    )}
                  </Col>

                  {/* Nueva sección de Sort By */}
                  <Col xs={12} className="mt-3">
                    <label>
                      {isLoading ? <Skeleton width={80} /> : t("Sort By")}
                    </label>
                    {isLoading ? (
                      <Skeleton height={38} />
                    ) : (
                      <select
                        className="form-select w-full"
                        name="sortBy"
                        onChange={e => {
                          const sortBy = e.target.value
                          // Lógica para manejar el sortBy seleccionado
                          console.log("Selected sort:", sortBy)
                          // Puedes hacer algo como props.updateSortBy(sortBy)
                        }}
                      >
                        <option value="company-a-z">{t("A-Z")}</option>
                        <option value="company-z-a">{t("Z-A")}</option>
                        <option value="most-ibbs">
                          {t("Most IBBs accumulated")}
                        </option>
                        <option value="least-ibbs">
                          {t("Least IBBs accumulated")}
                        </option>
                        <option value="next-raffle">{t("Next Raffle")}</option>
                      </select>
                    )}
                  </Col>
                </Row>
                <hr />
                <button className="btn btn-primary w-100" disabled={isLoading}>
                  {isLoading ? (
                    <Skeleton width={60} />
                  ) : (
                    <>
                      <i className="fa fa-filter me-2"></i>
                      {t("Filter")}
                    </>
                  )}
                </button>
              </Form>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
      <Col xs={12} md={8} lg={9}>
        {children}
        {isLoading ? (
          <Skeleton height={40} />
        ) : (
          <>
            <Card>
              <Card.Body className="p-1 pt-2">
                <Pagination
                  totalPages={totalPages}
                  currentPage={page}
                  onPageChanged={onPageChanged}
                />
              </Card.Body>
            </Card>
          </>
        )}
      </Col>
    </Row>
  )
}

export default CompaniesFilter
