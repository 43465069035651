import { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import Settings from "../../../common/Settings"
import Timer from "../../Common/Timer"

const ManageGoals = props => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  console.log("manage goals", props)
  const [charity, setCharity] = useState<any>(props.record)
  // const requestCompanies = async () => {
  //   let requestAPI = await API.getAction(
  //     "charity/RelatedCompanies?id=" + props.id
  //   )

  //   if (requestAPI.data.status === "ok") {
  //     console.log(requestAPI.data.response)
  //     setRecords(requestAPI.data.response)
  //   }
  // }
  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      let query =
        "charityId=" + charity.id + "&pointsGoal=" + charity.pointsGoal
      const requestAPI = await API.getAction("charity/assignGoal", query)
      if (requestAPI.data.status === "ok") {
        props.toggle()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    // if (props.id) requestCompanies()
  }, [props.id])
  return (
    <Modal show={true} onHide={props.toggle} centered>
      <Modal.Header>
        <Modal.Title>{t("Manage Goal")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12}>
              <label>{t("Amount of IBBs to raise")}</label>
              <input
                type="number"
                name="amount"
                className="form-control"
                value={charity.pointsGoal ?? ""}
                onChange={e => {
                  let value: number = parseFloat(e.target.value)
                  value = value * 1
                  setCharity({ ...charity, pointsGoal: value })
                }}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {isLoading && (
          <div className="me-4 d-inline-block">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <button
          className="btn btn-secondary"
          onClick={props.toggle}
          disabled={isLoading}
        >
          {t("Cancel")}
        </button>
        <button
          className="btn btn-primary"
          disabled={isLoading}
          onClick={() => handleSubmit()}
        >
          {t("Save")}
        </button>
      </Modal.Footer>

      {/* </Modal.Dialog> */}
    </Modal>
  )
}

export default ManageGoals
