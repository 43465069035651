import React, { useState } from "react"
import { Col, Row, Form, Card, Button, Container } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import * as yup from "yup"
import { Routes } from "../../routes"
// import BgImage from "../../assets/img/illustrations/signin.svg"
import API from "../../common/API"
import toast, { Toaster } from "react-hot-toast"
import { useFormik } from "formik"
import Sha256 from "../../common/sha256"
// import logo from "../../assets/img/logoPleeloTexto.png"

export interface UserRegister {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword?: string
}

export interface UserRegisterErrorResp {
  status: string
  response: string
  quantityOfPages: number
  quantityOfRecords: number
  page: number
  quantityPerPage: number
  needsLogin: boolean
}

//Translating
import { useTranslation } from "react-i18next"
import { AxiosError } from "axios"
import Logo from "../Common/Logo"

const Register = () => {
  const { t } = useTranslation()

  const validationSchema = yup.object({
    firstName: yup.string().required(t("Name is a required field")),
    lastName: yup.string().required(t("Last name is a required field")),
    email: yup
      .string()
      .required(t("Email is a required field"))
      .email(t("Email must be a valid email")),
    password: yup
      .string()
      .required(t("Password is a required field"))
      .min(6, t("Password must be at least 6 characters")),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], t("Passwords must match")),
  })
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (obj: UserRegister) => {
    console.log("values to submit", obj)

    let queryData = {
      firstName: obj.firstName,
      lastName: obj.lastName,
      email: obj.email,
      password: Sha256.hash(obj.password),
    }
    try {
      setIsLoading(true)
      let request = await API.postAction("account/Add", queryData)
      console.log(request)

      if (request.status === 200) {
        history.push(Routes.SignIn.path)
      }
    } catch (error) {
      const errorResponse = error as AxiosError<UserRegisterErrorResp>

      if (errorResponse?.response?.data.response === "account_exist") {
        toast.error(t("is registered"))
      } else {
        toast.error(t(errorResponse?.response?.data.response as string))
      }
    } finally {
      setIsLoading(false)
      formik.setFieldValue("password", "")
      formik.setFieldValue("confirmPassword", "")
    }
  }

  const formik = useFormik<UserRegister>({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      email: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <>
      <Toaster />
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <div className="text-center">
              <Link to={Routes.Home.path}>
                <Logo logoName="logo2" alt="logo" className="mb-4" />
              </Link>
            </div>
            <Row
              className="justify-content-center form-bg-image"
              // style={{ backgroundImage: `url(${BgImage})` }}
            >
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">{t("Create Account")}</h3>
                  </div>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col xs={6}>
                        <Form.Label>{t("Firstname")}</Form.Label>
                        <Form.Control
                          autoFocus
                          type="text"
                          name="firstName"
                          placeholder={t("Firstname")}
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.firstName ? (
                          <div className="invalid text-sm">
                            {formik.errors.firstName}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={6}>
                        <Form.Label>{t("Lastname")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder={t("Lastname")}
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.lastName ? (
                          <div className="invalid text-sm">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </Col>

                      <Col xs={12}>
                        <Form.Label>{t("Email")}</Form.Label>
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder={t("Email")}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.email ? (
                          <div className="invalid text-sm">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={12}>
                        <Form.Label>{t("Password")}</Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder={t("Password")}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.password ? (
                          <div className="invalid text-sm">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={12}>
                        <Form.Label>{t("Conf. password")}</Form.Label>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          placeholder={t("Conf. password")}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.confirmPassword ? (
                          <div className="invalid text-sm">
                            {formik.errors.confirmPassword}
                          </div>
                        ) : null}
                      </Col>
                      <Col xs={12}>
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isLoading}
                          className="w-100 mt-4"
                        >
                          {t("Signin")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      {t("have account?")}&nbsp;
                      <Card.Link
                        as={Link}
                        to={Routes.SignIn.path}
                        className="fw-bold"
                      >
                        {t("Login")}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default Register
