import { Col, Container, Row } from "react-bootstrap"

import { useTranslation } from "react-i18next"
import googlePlay from "../../assets/img/play-store.png"
import appStore from "../../assets/img/app-store.png"
interface FooterProps {
  fixedBottom?: boolean
}

const Footer = ({ fixedBottom = true }: FooterProps) => {
  const { t } = useTranslation()
  return (
    <footer
      className={`bg-white py-4 border-top ${fixedBottom && "fixed-bottom"}`}
    >
      <Container>
        <Row>
          <Col md={6} xs={12}>
            {/* <div>
              <Link to={Routes.Home.path}>
                <Logo
                  logoName="logo2"
                  className="d-inline-block align-top"
                  alt="ibbundance"
                  width={30}
                />
              </Link>
              <div></div>
            </div> */}

            <p>
              © Copyright {new Date().getFullYear()} Ibbundance.{" "}
              {t("All Rights Reserved.")}
            </p>
            <p>
              <a href="#" className="me-2">
                {t("Terms")}
              </a>
              <a href="#" className="me-2">
                {t("Privacy Notice")}
              </a>
              <a href="#" className="me-2">
                {t("Legal")}
              </a>
            </p>
          </Col>

          <Col md={6} xs={12} className="text-end">
            <a
              href="#"
              target="_blank"
              rel="noreferrer"
              title="instagram"
              className="me-2"
            >
              <i className="fa-brands fa-instagram fa-2x"></i>
            </a>
            <a href="#" target="_blank" rel="noreferrer" title="youtube">
              <i className="fa-brands fa-youtube fa-2x"></i>
            </a>
            <p className="my-4">
              <a href="#" className="me-2">
                <img src={googlePlay} alt="Google Play" />
              </a>
              <a href="#" className="">
                <img src={appStore} alt="App Store" />
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
