interface NoDataProps {
  withFullHeight?: boolean
}

const NoData = ({ withFullHeight = true }: NoDataProps) => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: withFullHeight ? "100vh" : "20vh" }}
    >
      Not Data :´(
    </div>
  )
}

export default NoData
