import { useEffect, useState } from "react"
import { Accordion, Col, Container, Row } from "react-bootstrap"
import API from "../../common/API"
import { faqI } from "../../common/interface/Faq.interface"
import ButtonLoadMore from "../Common/ButtonLoadMore"
import { PaginationI } from "../../common/interface/Pagination.interface"
import Loading from "../Loading"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const FaqContainer = () => {
  const { t } = useTranslation()

  const [faq, setFaq] = useState([])
  const [loading, setLoading] = useState(false)
  const [paginationState, setPaginationState] = useState<PaginationI>({
    page: 0,
    quantityOfPages: 1,
    quantityOfRecords: 3,
    quantityPerPage: 0,
    currentQuantity: 10,
    defaultIncrease: 10,
  })

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

  const handleToggle = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index)
  }
  const increaseCurrentQuantity = () => {
    setPaginationState(prevState => ({
      ...prevState,
      currentQuantity: prevState.currentQuantity + prevState.defaultIncrease,
    }))
  }

  const requestFAQ = async () => {
    try {
      setLoading(true)
      let queryString = `name=${""}&Quantity=${10}&Page=${0}`
      let requestAPI = await API.getAction("faq/list?" + queryString)

      setFaq(requestAPI.data.response)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    requestFAQ()
  }, [])

  return (
    <Container className="mt-5  bg-main">
      <Row>
        <Col xs={12}>
          <div className="text-center mb-3">
            <h1 className="text-primary display-1">{t("FAQ")}</h1>
            <span className="text-muted fs-2">
              {t("If you cant find what you are looking for, you can write us")}
            </span>
          </div>
        </Col>
        <Col xs={12} className="mt-3 mb-5 bg-more-info">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-100">
              <Loading />
            </div>
          ) : (
            <>
              {faq.map((item: faqI, index) => (
                <div
                  key={index}
                  className="px-4 md:px-5 pt-2 md:pt-4 lg:pt-5 mb-4 md:mb-2 d-flex justify-content-between align-items-center gap-5"
                  style={{ maxWidth: "54rem", margin: "0 auto" }}
                  onClick={() => handleToggle(index)}
                >
                  <div className="text-start">
                    <h2 className=" font-bold poppins-bold titles">
                      {item.title}
                    </h2>
                    {expandedIndex === index ? (
                      <p className="poppins-regular">{item.content}</p>
                    ) : null}
                  </div>
                  <div className="flex items-end">
                    <Link to={"#"} className="btn-more-info">
                      <i
                        className={`fa-solid ${
                          expandedIndex === index
                            ? "fa-chevron-down"
                            : "fa-chevron-right"
                        }  text-primary`}
                      />
                    </Link>
                  </div>
                </div>
              ))}

              <div className="d-flex justify-content-center">
                <ButtonLoadMore
                  increaseCurrentQuantity={increaseCurrentQuantity}
                  paginationState={paginationState}
                />
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default FaqContainer
