import React from "react"
import { Image } from "react-bootstrap"
import ReactLogo from "../assets/img/logo_ibbundance.png"

const Loading = ({ className = "mx-auto", centered = false }) => {
  return (
    <Image
      style={{
        margin: centered ? "auto" : "",
        display: centered ? "block" : "",
        marginRight: "1rem",
      }}
      className={`rotate  ${className} `}
      src={ReactLogo}
      height={100}
    />
  )
}

export default Loading
