import { useTranslation } from "react-i18next"
import CRUD from "../../core/genericCRUD/CRUD"
import { FC } from "react"
import { parsedUser } from "../../../common/GetCurrentUser"

interface CharityComponentProps {
  show: boolean
  toggle: (sectionName: string) => void
}

const CharityComponent: FC<CharityComponentProps> = ({ show, toggle }) => {
  const { t } = useTranslation()

  const user = parsedUser()

  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={show}
      // modalSize="lg"
      toggleCRUD={() => toggle("")}
      title={t("Foundation") ?? ""}
      getRecordsPath={`charity/list`}
      addEditRecordPath={`charity/AddEdit`}
      deleteRecordPath={`charity/Delete`}
      fieldsList={[
        /* {
          name: "id",
          title: t("id"),
        }, */
        {
          name: "logo",
          title: t("logo"),
          htmlType: "picture",
          cssClass: "text-center",
        },
        {
          name: "name",
          title: t("name"),
        },

        {
          name: "website",
          title: t("website"),
        },
        {
          name: "phone",
          title: t("phone"),
        },
        /* {
          name: "ownerId",
          title: t("owner"),
        }, */
        {
          name: "charityStatusId",
          title: t("status"),
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "logo",
          htmlType: "picture",
          dbType: "blob",
          title: t("logo") ?? "",
          typeFile: "image/*",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "name",
          htmlType: "text",
          placeHolder: t("What's your charity name?"),
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },

        {
          name: "phone",
          htmlType: "text",
          placeHolder: t("What's your charity phone?"),
          dbType: "string",
          title: t("phone") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },

        {
          name: "ownerId",
          htmlType: "hidden",
          dbType: "string",
          value: user.ID,
        },
        {
          name: "website",
          htmlType: "text",
          placeHolder: t("Enter your URL website"),
          dbType: "string",
          title: t("website") ?? "",
          containerClass: "col-md-6 col-12",
          required: true,
        },
        {
          name: "description",
          htmlType: "textarea",
          placeHolder: t("talk about your charity"),
          dbType: "string",
          title: t("description") ?? "",
          containerClass: "col-12",
          required: true,
        },
      ]}
      filterFields={[
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-4",
          required: false,
        },
      ]}
    />
  )
}

export default CharityComponent
