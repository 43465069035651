import { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import Settings from "../../../common/Settings"
import Timer from "../../Common/Timer"

const Donate = props => {
  const [selectedCompany, setSelectedCompany] = useState<any>(null)
  const [records, setRecords] = useState<any>([])
  const [amountToDonate, setAmountToDonate] = useState<number | null>(null)
  const { t } = useTranslation()

  const requestCompanies = async () => {
    let requestAPI = await API.getAction(
      "charity/RelatedCompanies?id=" + props.id
    )

    if (requestAPI.data.status === "ok") {
      console.log(requestAPI.data.response)
      setRecords(requestAPI.data.response)
    }
  }
  useEffect(() => {
    if (props.id) requestCompanies()
  }, [props.id])
  return (
    <Modal show={true} onHide={props.toggle} centered size="lg">
      <Modal.Header>
        <Modal.Title>{t("Donate")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <h4>1. {t("Select related company")}</h4>
              <Row>
                {records.length > 0 &&
                  records.map((item: any, index: number) => (
                    <Col key={index} className=" py-2" xs={12} md={6}>
                      <Card className="clickable ">
                        <Card.Body>
                          <Row className=" rounded ">
                            <Col className="col-auto">
                              <img
                                src={`${Settings.BasePath}files/${item.logo}`}
                                alt={item.name}
                                style={{
                                  width: "32px",
                                  maxHeight: "32px",
                                  objectFit: "cover",
                                  borderRadius: "0.25rem",
                                }}
                                loading="lazy"
                              />
                            </Col>
                            <Col>
                              <span>{item.name}</span>
                              <br />
                              <small className="text-primary me-2">
                                {t("IBBs")} 250,000.00
                              </small>
                              <br />
                              <small className="text-primary me-2">
                                {t("Next Raffle")}&nbsp;
                                <Timer deadline={"2024-12-31T23:59:59"} />
                              </small>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col xs={12} md={12} className="mt-2">
              <h4>2. {t("How much you would like to donate?")}</h4>
              <div className="input-group">
                <span className="input-group-text">{t("IBBs")}</span>
                <input
                  type="number"
                  className="form-control"
                  value={amountToDonate ?? 0}
                  onChange={(e: any) => {
                    let val: number = e.target.value * 1
                    setAmountToDonate(val)
                  }}
                />
              </div>
            </Col>
            <Col xs={12} className="mt-2 py-4">
              <h4>3. {t("How you would like to donate?")}</h4>
              <button
                className="btn bg-white text-dark me-2"
                disabled={!selectedCompany || !amountToDonate}
              >
                {t("Donate as guest")}
              </button>
              <span className="mx-2">{t("or")}</span>
              <button
                className="btn btn-primary"
                disabled={!selectedCompany || !amountToDonate}
              >
                {t("Donate")}
              </button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>

      {/* </Modal.Dialog> */}
    </Modal>
  )
}

export default Donate
