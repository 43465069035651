import { useEffect, useState } from "react"
import { Col, Container, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import Settings from "../../../common/Settings"

const ReportIssue = props => {
  const [selected, setSelected] = useState<any>(null)
  const [records, setRecords] = useState<any>([])
  const [note, setNote] = useState<string | null>(null)
  const { t } = useTranslation()

  const requestCategories = async () => {
    let requestAPI = await API.getAction("reportCategory/list")

    if (requestAPI.data.status === "ok") {
      console.log(requestAPI.data.response)
      setRecords(requestAPI.data.response)
    }
  }
  useEffect(() => {
    requestCategories()
  }, [])
  return (
    <Modal show={true} onHide={props.toggle} centered>
      <Modal.Header>
        <Modal.Title>{t("Report Issue")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12}>
              <label>{t("Category")}</label>
              <select
                className="form-control"
                value={selected}
                onChange={e => setSelected(e.target.value)}
              >
                <option value="">-</option>
                {records.length > 0 &&
                  records.map((record: any, index: number) => (
                    <option value={record.id} key={index}>
                      {record.name}
                    </option>
                  ))}
              </select>
            </Col>
            <Col xs={12}>
              <label>{t("Details")}</label>
              <textarea
                className="form-control"
                value={note ?? ""}
                onChange={(e: any) => {
                  setNote(e.target.value)
                }}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <b className="me-2">3.</b>
        <button className="btn btn-primary" disabled={!selected || !note}>
          {t("Report")}
        </button>
      </Modal.Footer>
      {/* </Modal.Dialog> */}
    </Modal>
  )
}

export default ReportIssue
