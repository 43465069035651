import React from "react"
import { Nav, NavDropdown } from "react-bootstrap"
import { parsedUser } from "../../../common/GetCurrentUser"
import { useHistory } from "react-router"
import { Routes } from "../../../routes"
import ClearUser from "../../../common/ClearUser"
import { useTranslation } from "react-i18next"
import ProfilePicture from "./ProfilePicture"

const UserBtnComponent = () => {
  const user = parsedUser()

  const history = useHistory()
  const { t } = useTranslation("global")
  return user ? (
    <NavDropdown title={<ProfilePicture user={user} />} className="text-white">
      <NavDropdown.Item
        as="button"
        onClick={() => history.push(Routes.Profile.path)}
        className="header-item "
      >
        {t("profile")}
      </NavDropdown.Item>

      <NavDropdown.Item
        as="button"
        onClick={() => {
          ClearUser()
          history.push(Routes.Home.path)
        }}
        className="text-danger"
      >
        {t("logout")}
      </NavDropdown.Item>
    </NavDropdown>
  ) : (
    <Nav.Link
      as="button"
      onClick={() => {
        history.push(Routes.SignIn.path)
      }}
      className="btn btn-sm text-white bg-primary mt-4 mt-lg-0 px-3 "
    >
      Sign In
    </Nav.Link>
  )
}

export default UserBtnComponent
