import React, { useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Routes } from "../../routes"
import AdminUsersComponent from "./AdminUsersComponent"
import Settings from "../Settings/Settings"
import AdminOrganizationsComponent from "./AdminOrganizationsComponent"
import DashboardComponent from "./Dashboard/DashboardComponent"
import AdminCharityComponent from "./AdminCharityComponent"

function AdminContainer() {
  const { t } = useTranslation("global")
  const [section, setSection] = useState<string>("dashboard")
  return (
    <Container>
      <Row>
        <Col xs={12} md={3} lg={2}>
          <div className="my-4 bg-white shadow-sm border-end px-2 py-2">
            <h5>{t("Menu")}</h5>
            <hr className="text-light bg-light" />
            <ul className="list-unstyled">
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "dashboard" ? "active" : "")
                }
                onClick={() => setSection("dashboard")}
              >
                {t("Dashboard")}
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "foundations" ? "active" : "")
                }
                onClick={() => setSection("foundations")}
              >
                {t("Foundations")}
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "companies" ? "active" : "")
                }
                onClick={() => setSection("companies")}
              >
                {t("Companies")}
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "users" ? "active" : "")
                }
                onClick={() => setSection("users")}
              >
                {t("Users")}
              </li>
              <li
                className={
                  `clickable  border-bottom py-2 px-2 mb-2 ` +
                  (section === "settings" ? "active" : "")
                }
                onClick={() => setSection("settings")}
              >
                {t("Settings")}
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={12} md={9} lg={10}>
          <div className="mt-4" />
          {section === "dashboard" && <DashboardComponent />}
          {section === "users" && <AdminUsersComponent />}
          {section === "companies" && <AdminOrganizationsComponent />}
          {section === "foundations" && <AdminCharityComponent />}
          {section === "settings" && <Settings />}
        </Col>
      </Row>
    </Container>
  )
}

export default AdminContainer
