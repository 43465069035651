import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"

import Item from "../Common/Category/Item"

import Slider from "react-slick"
import API from "../../common/API"
import Settings from "../../common/Settings"
import { useTranslation } from "react-i18next"

import Skeleton from "react-loading-skeleton"
import { useHistory } from "react-router"
import { Routes } from "../../routes"

const PromotedFoundationsComponent = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const [records, setRecords] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  }
  const bindDataRemote = async () => {
    try {
      setIsLoading(true)
      const request = await API.getAction("charity/list")
      if (request.data.status === "ok") {
        let data = request.data.response
        setRecords(
          data.map((item: any) => {
            let img = `${Settings.BasePath}files/${item.logo}`
            return {
              id: item.id,
              name: item.name,
              img: img,
              progress: 25,
              raised: 50,
              goal: 100,
              location: item.website,
            }
          })
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    bindDataRemote()
  }, [])
  if (records.length === 0) return null
  return (
    <Container fluid className="pt-5 rounded-5 mb-4 mb-md-5 ">
      <Row className="d-flex justify-content-between align-items-end flex-wrap flex-md-wrap mb-2 mx-4">
        <Col xs={12} md={10} className="">
          <h5 className="subtitle text-primary">
            {isLoading ? <Skeleton width={200} /> : t("Foundations")}
          </h5>
          <p className="paragraph text-muted fs-4 text-black">
            {isLoading ? (
              <Skeleton count={2} />
            ) : (
              t("Discover fundraisers inspired by what you care about")
            )}
          </p>
        </Col>
        <Col
          className="d-flex gap-2 justify-content-end d-none"
          style={{ height: "4rem" }}
          md={2}
        >
          {isLoading ? (
            <>
              <Skeleton circle height={40} width={40} />
              <Skeleton circle height={40} width={40} />
            </>
          ) : (
            <>
              <button className="btn px-4 btn-ghost-primary-light d-none">
                <i className="fa-solid fa-chevron-left text-primary"></i>
              </button>
              <button className="btn px-4 btn-ghost-primary-light d-none">
                <i className="fa-solid fa-chevron-right text-primary"></i>
              </button>
            </>
          )}
        </Col>
      </Row>
      {/* desktop view */}
      <Row className="mx-3">
        <Col xs={12} md={7} lg={6} className="d-none d-md-block d-lg-block">
          {isLoading ? (
            <Skeleton height={510} width={600} />
          ) : (
            <Item
              main
              id={records[0].id}
              title={records[0].name}
              img={records[0].img}
              progress={records[0].progress}
              raised={records[0].raised}
              imgFooter={records[0].location}
              //customHeight="510"
              customWidth="100%"
            />
          )}
        </Col>
        <Col xs={12} md={5} lg={6} className="d-none d-md-block d-lg-block">
          <Row>
            {isLoading
              ? [1, 2, 3].map((_, index) => (
                  <Skeleton key={index} height={160} width={250} />
                ))
              : records.slice(1).map((item, index) => {
                  if (index === 0) return
                  return (
                    <Col key={index} xs={6}>
                      <Item
                        id={item.id}
                        title={item.name}
                        img={item.img}
                        customHeight="100%"
                        progress={item.progress}
                        raised={item.raised}
                        imgFooter={item.location}
                        customWidth="100%"
                      />
                    </Col>
                  )
                })}
          </Row>
        </Col>
      </Row>

      {/* mobile view */}
      <Row className="d-block d-md-none mx-3">
        <Slider {...settings}>
          {records.map((item, index) => {
            if (index === 0) return
            return (
              <div
                onClick={() =>
                  history.push(`${Routes.FoundationDetails.clean}${item.id}`)
                }
                key={index}
                className=" clickable p-2 text-truncate"
              >
                <img
                  src={item.img}
                  alt={item.name}
                  style={{
                    height: "160px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  className="img-fluid img-thumbnail"
                />
                <span className="text-primary poppins-bold">{item.name}</span>
              </div>
            )
          })}
        </Slider>
      </Row>
    </Container>
  )
}

export default PromotedFoundationsComponent
