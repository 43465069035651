import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Image,
  Button,
  InputGroup,
  Form,
  Dropdown,
} from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import { useFormik } from "formik"

import * as yup from "yup"
import { parsedUser } from "../../../common/GetCurrentUser"
import { useParams } from "react-router"
import { CommentCharity } from "../../../common/interface/Comment.interface"
import { timeAgo } from "../../../helpers/timeAgo"
import Settings from "../../../common/Settings"
import { AbstractName } from "../../../common/helpers"
import Pagination from "../../core/Pagination"
import NoData from "../../NoData"

export interface CommentInsertI {
  CharityId?: string
  AccountId?: string
  Comment: string
}

const initialValues: CommentInsertI = {
  Comment: "",
}

interface routeParams {
  id: string | undefined
}

const CommentSection = () => {
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [quantity] = useState(5)

  const onPageChanged = (obj: number) => {
    setPage(obj)
  }

  const { t } = useTranslation()
  const { id } = useParams<routeParams>()
  const user = parsedUser()
  const [imageLoaded, setImageLoaded] = useState(true)

  const schema = yup.object({
    Comment: yup.string().required(t("is a required field")),
  })

  const [comments, setComments] = useState<CommentCharity[]>()

  const handleImageError = () => {
    setImageLoaded(false)
  }

  const requestComments = async () => {
    try {
      const queryString = `name=${""}&quantity=${quantity}&page=${page}&CharityId=${id}`
      const requestAPI = await API.getAction(
        "CharityComment/List?" + queryString
      )

      console.log(requestAPI)

      if (requestAPI.data.status === "ok") {
        setTotalPages(requestAPI.data.quantityOfPages)
        setComments(requestAPI.data.response)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async (obj: CommentInsertI) => {
    try {
      obj.CharityId = id
      obj.AccountId = user.ID

      const requestAPI = await API.postAction("CharityComment/AddEdit", obj)

      console.log(requestAPI)

      if (requestAPI.data.status === "ok") {
        requestComments()

        formik.resetForm()
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    requestComments()
  }, [id, page])

  const formik = useFormik<CommentInsertI>({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  return (
    <>
      {/* Primer comentario */}
      <span className="poppins-bold">{t("Comments")}</span>
      <hr />
      <Row>
        <Col xs={12} style={{ zIndex: "1" }}>
          <Form onSubmit={formik.handleSubmit}>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={t("Add a comment") + "..."}
                aria-label="Add a comment"
                className="rounded-pill"
                autoFocus
                name={"Comment"}
                value={formik.values.Comment}
                onChange={formik.handleChange}
              />

              <InputGroup.Text className="bg-white border-0">
                <Button
                  variant="primary"
                  type="submit"
                  className="rounded-pill ms-3"
                >
                  <i className="fa-solid fa-paper-plane" /> {t("Send")}
                </Button>
              </InputGroup.Text>
            </InputGroup>
            <Dropdown>
              <Dropdown.Toggle
                variant="link"
                id="dropdown-basic"
                className="text-muted"
              >
                {t("Most relevant")}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  {t("Most relevant")}
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">{t("Newest")}</Dropdown.Item>
                <Dropdown.Item href="#/action-3">{t("Oldest")}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form>
        </Col>

        {comments && comments.length > 0 ? (
          comments.map((comment: CommentCharity, index) => {
            let fullName =
              comment.account.firstName + " " + comment.account.lastName
            return (
              <Row key={index} className="my-3">
                <Col xs="auto">
                  {imageLoaded ? (
                    <Image
                      src={`${Settings.BasePath}files/${comment.account.pic}`}
                      roundedCircle
                      style={{ width: "50px" }}
                      onError={handleImageError} // Si hay un error, se ejecuta esta función
                    />
                  ) : (
                    <div className="user-avatar rounded bg-primary rounded-5">
                      {AbstractName(fullName)}
                    </div>
                  )}
                </Col>
                <Col>
                  <strong>{fullName}</strong>{" "}
                  <span className="text'muted">
                    {" "}
                    · {timeAgo(comment.created)}
                  </span>
                  <div className="text-muted">
                    <p>{comment.comment}</p>
                  </div>
                  <div className="mt-2">
                    <Button variant="link" className="p-0">
                      <i className="fa-regular fa-thumbs-up"></i> 0
                    </Button>
                  </div>
                </Col>
              </Row>
            )
          })
        ) : (
          <NoData withFullHeight={false} />
        )}
        <Col xs={12}>
          <hr />
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChanged={onPageChanged}
          />
        </Col>
      </Row>
    </>
  )
}

export default CommentSection
