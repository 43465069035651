import React, { useEffect, useState } from "react"

import LinkSeeMore from "../Common/LinkSeeMore"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"
import API from "../../common/API"
import Settings from "../../common/Settings"
import { Container, Row } from "react-bootstrap"

import Slider from "react-slick"
import Timer from "../Common/Timer"
import { Routes } from "../../routes"
import { useHistory } from "react-router"

interface CompaniesViewListProps {
  title: string
  hideAllEntity?: boolean
  hideCouldDown?: boolean
  hideEntityTitle?: boolean
}

/**
 * The props that begin with hide are temporary, they are simply to represent
 * the different ideas. When these ideas are consolidated, separate components
 * will be created and these conditions will be eliminated.
 *
 * @param param0
 * @returns
 */

const CompaniesViewList = ({
  title,
  hideAllEntity = true,
  hideCouldDown = true,
  hideEntityTitle = false,
}: CompaniesViewListProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [records, setRecords] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  }

  const settingsMobile = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  }
  const bindDataRemote = async () => {
    try {
      changeIsLoading(true)
      const request = await API.getAction("company/list")
      if (request.data.status === "ok") {
        let data = request.data.response

        console.log(data)
        setRecords(
          data.map((item: any) => {
            let img = `${Settings.BasePath}files/${item.logo}`
            return {
              id: item.id,
              name: item.name,
              img: img,
              progress: 25,
              raised: 50,
              goal: 100,
              nextRaffle: item.nextRaffle,
              location: item.website,
            }
          })
        )
      }
    } catch (error) {
      console.error(error)
    } finally {
      changeIsLoading(false)
    }
  }

  const changeIsLoading = (status: boolean) => setIsLoading(status)

  useEffect(() => {
    bindDataRemote()
  }, [])

  return (
    <Container fluid className=" mt-5">
      {/* Companies */}
      <Row className="mx-3">
        <h5 className="subtitle text-primary ms-3">
          {isLoading ? <Skeleton width={200} /> : t(title)}
        </h5>
        {/* DESKTOP */}
        <div className="d-none d-md-block">
          <Slider {...settings}>
            {records.map((item, index) => {
              if (index === 0) return
              return (
                <div
                  key={index}
                  onClick={() => {
                    history.push(Routes.CompanyDetails.clean + item.id)
                  }}
                  className="clickable p-2 text-truncate"
                >
                  <img
                    src={item.img}
                    alt={item.name}
                    style={{
                      height: "18.75rem",
                      width: "18.75rem",
                      objectFit: "cover",
                    }}
                    className="img-fluid img-thumbnail"
                  />
                  <div className="ms-1">
                    <div className="text-secondaryColor mb-1 ">
                      {item && !hideCouldDown && item.nextRaffle !== null ? (
                        <Timer deadline={item.nextRaffle} />
                      ) : (
                        "The time has expired!"
                      )}

                      {item && !hideCouldDown && item.ibbs ? (
                        <>
                          <br />
                          {t("IBBs")} {item.ibbs}
                        </>
                      ) : null}
                    </div>

                    {!hideEntityTitle ? (
                      <span className="text-primary poppins-bold ">
                        {item.name}
                      </span>
                    ) : null}
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
        {/* MOBILE */}
        <div className="d-block d-md-none">
          <Slider {...settingsMobile}>
            {records.map((item, index) => {
              if (index === 0) return
              return (
                <div
                  key={index}
                  onClick={() => {
                    history.push(Routes.CompanyDetails.clean + item.id)
                  }}
                  className="clickable p-2 text-truncate"
                >
                  <img
                    src={item.img}
                    alt={item.name}
                    style={{
                      height: "160px",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    className="img-fluid img-thumbnail"
                  />
                  <div className="text-secondaryColor">
                    {item && !hideCouldDown && item.nextRaffle !== null ? (
                      <Timer deadline={item.nextRaffle} />
                    ) : (
                      "The time has expired!"
                    )}
                    <br />
                    {!hideCouldDown && item && item.ibbs
                      ? t("IBBs") + " " + item.ibbs
                      : null}
                  </div>

                  {!hideEntityTitle ? (
                    <span className="text-primary poppins-bold ">
                      {item.name}
                    </span>
                  ) : null}
                </div>
              )
            })}
          </Slider>
        </div>
      </Row>
      <div className="my-4" />
      {!hideAllEntity ? (
        isLoading ? (
          <Skeleton width={150} height={40} className="d-block mx-auto" />
        ) : (
          <LinkSeeMore
            route="/companies"
            text={t("All Companies")}
            positionButton="center"
          />
        )
      ) : null}
    </Container>
  )
}

export default CompaniesViewList
