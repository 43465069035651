import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { useTranslation } from "react-i18next"
import API from "../../common/API"
import Settings from "../../common/Settings"
import { Search, Dropdown } from "semantic-ui-react"
import CRUD from "./genericCRUD/CRUD"
const FieldSelector = (props: any) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [records, setRecords] = useState([])
  const [photo, setPhoto] = useState<string | null>(null)
  const model = props.model

  if (!model) {
    return
  }

  // const searchCedula = async () => {
  //   try {
  //     setLoading(true)
  //     const requestAPI = await API.getActionExternal(
  //       Settings.BasePathForAPIPADRON + "Cedulados/Detalle?id=" + props.value
  //     )

  //     props.resultadosCedula(requestAPI.data.response)
  //     console.log(requestAPI)
  //   } catch (e) {
  //     console.error(e)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const requestRecords = async () => {
    let query = ""
    if (model.dependOf && props.obj) {
      query = `${model.dependOf}=${props.obj[model.dependOf]}`
    }
    if (model.dataSourceLocal) {
      setRecords(
        model.dataSourceLocal.map(item => {
          return {
            value: item.id,
            text: item.name,
          }
        })
      )

      return
    }

    if (model.dataSourceMethod === "POST") {
      let postQuery = {
        Search: null,
        Quantity: 500,
        Page: 0,
      }
      let requestAPI: any = await API.postAction(model.dataSource, postQuery)

      if (requestAPI.data.status === "ok") {
        setRecords(
          requestAPI.data.response.map((item, index) => {
            return {
              value: item[model.dataSourceValueField],
              text: item[model.dataSourceTextField],
            }
          })
        )
      }
    } else if (model.dataSourceMethod === "GET") {
      let requestAPI: any = await API.getAction(
        model.dataSource + (query.length > 0 ? "?" + query : "")
      )

      if (requestAPI.data.status === "ok") {
        let colectedRecords = requestAPI.data.response.map((item, index) => {
          return {
            value: item[model.dataSourceValueField],
            text: item[model.dataSourceTextField],
          }
        })

        console.log("collection ", colectedRecords)
        setRecords(colectedRecords)
      }
    }
  }

  const uploadImg = async (obj, field) => {
    let file = obj.target.files[0]
    console.log(file)
    // Ensure it's an image
    if (file.type.match(/image.*/)) {
      console.log("An image has been loaded")
      let bodyData = new FormData()
      bodyData.append("path", file)
      const request = await API.postAction(`File/Upload`, bodyData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      if (field.includes("photo")) setPhoto(request.data.response)
      props.updateField(model.name, request.data.response)
      //formik.setFieldValue(field, request.data.response)
    }
  }

  useEffect(() => {
    console.log(props.value, model)
    if (model.htmlType === "picture" && props.value) {
      setPhoto(props.value)
    }
    if (model.htmlType === "select" && props.value) {
      if (model.dataSourceLocal) {
        setRecords(
          model.dataSourceLocal.map(item => {
            return {
              value: item.id,
              text: item.name,
            }
          })
        )
      } else {
        requestRecords()
      }
    }
  }, [props.value])

  // useEffect(() => {
  //   console.log(model)
  //   if (model && model.dataSource && !model.dataSourceLocal) {
  //     requestRecords()
  //   } else if (model && model.dataSourceLocal) {
  //     console.log(model.dataSourceLocal)
  //     setRecords(model.dataSourceLocal)
  //   }
  // }, [model.dataSource, model.dataSourceLocal])

  const returnField = () => {
    console.log(model)
    switch (model.htmlType) {
      case "email":
      case "text":
      case "date":
      case "file":
      case "number":
      case "password":
      case "color":
        return (
          <input
            type={model.htmlType}
            className={"form-control"}
            name={model.name}
            value={props.value ?? ""}
            disabled={model.readOnly ?? false}
            onChange={e => {
              console.log(e.target.value)
              props.updateField(model.name, e.target.value)
            }}
          />
        )
      case "search":
        return (
          <Search name={model.name} className="" value={props.value ?? ""} />
        )
      // case "cedula":
      //   return (
      //     <div className="input-group">
      //       <input
      //         type="text"
      //         disabled={model.readOnly ?? false}
      //         className="form-control"
      //         name={model.name}
      //         value={props.value ?? ""}
      //         onChange={e => {
      //           console.log(e.target.value)
      //           props.updateField(model.name, e.target.value)
      //         }}
      //       />
      //       <div className="input-group-append">
      //         <button
      //           className="btn btn-outline-secondary"
      //           type="button"
      //           onClick={() => {
      //             searchCedula()
      //           }}
      //         >
      //           {loading ? (
      //             <div
      //               className="spinner-border spinner-border-sm"
      //               role="status"
      //             >
      //               <span className="sr-only">Loading...</span>
      //             </div>
      //           ) : (
      //             <i className="fa fa-search"></i>
      //           )}
      //         </button>
      //       </div>
      //     </div>
      //   )
      case "textarea":
        return (
          <textarea
            rows={5}
            disabled={model.readOnly ?? false}
            className={"form-control"}
            name={model.name}
            value={props.value ?? ""}
            onChange={e => {
              console.log(e.target.value)
              props.updateField(model.name, e.target.value)
            }}
          ></textarea>
        )
      case "crud":
        return (
          <CRUD
            fields={props.crudFields}
            getRecordsPath={props.crudGetRecordsPath}
            deleteRecordPath={props.crudDeleteRecordPath}
            addEditRecordPath={props.crudAddEditRecordPath}
            title={t(model.title) ?? ""}
            isModal={true}
            showModal={props.crudShow}
            toggleCRUD={props.crudToggle}
            fieldsList={props.crudFieldsList}
          />
        )
      case "picture":
        console.log(model, props.value)
        return (
          <div>
            <input
              type="file"
              disabled={model.readOnly ?? false}
              className="form-control"
              accept="image/*"
              name={model.name}
              onChange={e => {
                uploadImg(e, "photo")
              }}
              // value={props.value ?? ""}
            ></input>
            {photo && (
              <img
                alt=""
                className="img-fluid img-thumbnail my-2"
                style={{ width: "200px" }}
                id="image"
                src={
                  photo.includes("base64")
                    ? photo
                    : `${Settings.BasePath}/files/${photo}`
                }
              />
            )}
          </div>
        )

      case "select":
        console.log(model.dataSourceLocal)
        return (
          <Dropdown
            value={props.value ?? "-"}
            search
            className="form-control py-3"
            fluid
            disabled={model.readOnly ?? false}
            onChange={(e: SyntheticEvent, data: any) => {
              console.log(data, e)
              props.updateField(model.name, data.value)
            }}
            onOpen={requestRecords}
            options={
              model.dataSourceLocal
                ? model.dataSourceLocal.map(item => {
                    return {
                      value: item.id,
                      text: item.name,
                    }
                  })
                : records
            }
          />
        )

      case "bool":
        return (
          <button
            className={
              "btn " + (props.value ? "btn-primary" : "btn-outline-primary")
            }
            onClick={e => {
              props.updateField(model.name, !props.value)
            }}
          >
            {props.value ? (
              <i className="fa-solid fa-toggle-on "></i>
            ) : (
              <i className="fa-solid fa-toggle-off"></i>
            )}
          </button>
        )
      case "label":
        return <label>{props.value}</label>
      case "separator":
        return (
          <>
            <h4>{model.title}</h4>
            <hr />
          </>
        )
      case "container":
        return (
          <>
            <h4>{model.title}</h4>
            <hr />
          </>
        )

      default:
        return <></>
    }
  }

  // <select
  //   className={`select w-full`}
  //   name={model.name}
  //   value={props.value ?? ""}
  //   onChange={(e) => {
  //     props.updateField(model.name, e.target.value);
  //   }}
  // >
  //   <option value="">-</option>
  //   {records &&
  //     records.map((item, index) => {
  //       console.log(item);

  //       return (
  //         <option key={index} value={item[model.dataSourceValueField]}>
  //           {item[model.dataSourceTextField]}
  //         </option>
  //       );
  //     })}
  // </select>;
  //debugger;
  if (!model) {
    return <></>
  }
  return (
    <div className={model.containerClass + " my-2"}>
      {model.htmlType !== "separator" && (
        <label>
          {t(model.title)}
          {model.required && <i className="text-danger">*</i>}
          <br />
        </label>
      )}
      {returnField()}
      {props.errors && props.errors[model.name] ? (
        <div className="invalid text-sm text-danger">
          {props.errors[model.name]}
        </div>
      ) : null}
    </div>
  )
}

export default FieldSelector
