import enUs from "./en-US.json"
import esDo from "./es-DO.json"

export const resources = {
  "en-US": {
    translation: enUs,
  },
  "es-DO": {
    translation: esDo,
  },
}
