import React, { useEffect, useState } from "react"
import { Col, Row, Card, Form, Container, Tabs, Tab } from "react-bootstrap"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import { confirmAlert } from "react-confirm-alert"
//translation
import { useTranslation } from "react-i18next"
import languages from "../../common/languages"
import ChangePassword from "./ChangePassword"
import EditProfile from "./EditProfile"
import { AxiosResponse } from "axios"
import {
  AccountDetails,
  AccountDetailsResp,
  ParsedUserInterface,
} from "./profile.interface"
import NotificationItem from "../Common/Notification/NotificationItem"
import { notificationI } from "../../common/interface/Notification.interface"
import { PendingAdminExample } from "../../common/mock/PendingAdmin.mock"
import ProfileMyCompanies from "./ProfileMyCompanies"
import ProfileMyFoundations from "./ProfileMyFoundations"

const Profile = () => {
  const user: ParsedUserInterface = parsedUser()
  console.log(user)
  const [model, setModel] = useState<AccountDetails>({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
  })
  const [organizations, setOrganizations] = useState([])
  const [showChangePass, setShowChangePass] = useState(false)
  const [showEditProfile, setShowEditProfile] = useState(false)
  const { t, i18n } = useTranslation()

  const requestRemote = async () => {
    let request: AxiosResponse<AccountDetailsResp> = await API.getAction(
      "account/details?id=" + user.ID
    )

    if (request.data.status === "ok") {
      console.log(request.data.response, user)
      setModel(request.data.response)
    }
  }

  const requestOrganizationsRemote = async () => {
    return
    let request = await API.getAction(
      "organization/OrganizationsWhereTheUserBelong?AccountId=" + user.ID
    )

    if (request.data.status === "ok") {
      setOrganizations(request.data.response)
    }
  }

  const deleteConfirm = async (objOrganization, objUser) => {
    confirmAlert({
      title: t("confirm"),
      message: t("Sure_delete") + objOrganization.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "organization/Delete?organizationid=" +
                objOrganization.id +
                "&UserId=" +
                objUser
            )
            await requestOrganizationsRemote()
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => console.log("Click No"),
        },
      ],
    })
  }

  const leaveConfirm = async (objOrganization, objUser) => {
    confirmAlert({
      title: t("confirm"),
      message: t("Sure leave") + objOrganization.name + "?",
      buttons: [
        {
          label: t("yes"),
          onClick: async () => {
            await API.getAction(
              "organization/LeaveOrganization?organizationid=" +
                objOrganization.id +
                "&UserId=" +
                objUser
            )
            await requestOrganizationsRemote()
          },
        },
        {
          label: t("no"),
          onClick: () => console.log("Click No"),
        },
      ],
    })
  }

  useEffect(() => {
    requestRemote()
    requestOrganizationsRemote()
  }, [])

  const handlerSelectedLanguage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem("lng", e.target.value)
    i18n.changeLanguage(e.target.value)
  }

  const handleChangePassword = async (reload: boolean) => {
    console.log(reload)
    setShowChangePass(!showChangePass)
    if (reload) {
      await requestRemote()
      await requestOrganizationsRemote()
    }
  }

  const handleEditProfile = async (reload: boolean) => {
    setShowEditProfile(!showEditProfile)
    if (reload) {
      await requestRemote()
      await requestOrganizationsRemote()
    }
  }

  return (
    <Container className="py-5">
      <Row className="mx-0 mt-4">
        {showChangePass && (
          <ChangePassword toggle={handleChangePassword} show={showChangePass} />
        )}
        {showEditProfile && (
          <EditProfile
            toggle={handleEditProfile}
            show={showEditProfile}
            model={model}
          />
        )}
        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <Card className="mb-3">
                <Card.Body>
                  <h3>
                    {model?.firstName} {model?.lastName}
                  </h3>
                  <b>{t("email")}: </b>
                  <span>{model?.email}</span>
                  <div className="mt-4">
                    <div className="btn-group">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleEditProfile(false)}
                      >
                        {t("edit")}
                      </button>
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleChangePassword(false)}
                      >
                        {t("Change Password")}
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>
                  <Card.Title>{t("preferences")}</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={12}>
                      <b>{t("languages")}</b>
                      <Form.Select
                        defaultValue={i18n.language}
                        onChange={handlerSelectedLanguage}
                      >
                        <option value="" disabled>
                          {t("Select Language")}...
                        </option>
                        {languages.map(({ code, name }) => (
                          <option key={code} value={code}>
                            {name}
                          </option>
                        ))}
                      </Form.Select>
                      <hr />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={8} className="mt-xl-0 mt-3">
          <Tabs
            defaultActiveKey="companies"
            id="tab-pending-profile"
            className="mb-3 tabtobutton"
          >
            <Tab eventKey="companies" title={t("Companies")}>
              <Row>
                <Col xs={12}>
                  <ProfileMyCompanies userId={model?.id} />
                </Col>
                {/* {PendingAdminExample.slice(0, 3).map(
                  (item: notificationI, index) => {
                    return (
                      <Col xs={12} key={index}>
                        <NotificationItem
                          content={item.content}
                          createdAt={item.createdAt}
                          name={item.name}
                          typeNotification={item.typeNotification}
                        />
                      </Col>
                    )
                  }
                )} */}
              </Row>
            </Tab>
            <Tab eventKey="foundations" title={t("Foundations")}>
              <Row>
                <Col xs={12}>
                  <ProfileMyFoundations userId={model?.id} />
                </Col>
                {/* {PendingAdminExample.map((item: notificationI, index) => {
                  return (
                    <Col xs={12} key={index}>
                      <NotificationItem
                        content={item.content}
                        createdAt={item.createdAt}
                        name={item.name}
                        typeNotification={item.typeNotification}
                      />
                    </Col>
                  )
                })} */}
              </Row>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  )
}

export default Profile
