import React, { useEffect, useState } from "react"
import { Card, Carousel, Modal } from "react-bootstrap"

/* import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
 */ import API from "../../../common/API"
import { getYoutubeId } from "../../../common/helpers"
import { set } from "lodash"
const CharityVideos = props => {
  const [records, setRecords] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const requestRemote = async () => {
    try {
      setIsLoading(true)
      const res = await API.getAction(
        "charityVideo/list?charityId=" + props.charityId
      )
      if (res.data.status === "ok") {
        console.log(res.data.response)
        setRecords(res.data.response)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (props.charityId) {
      requestRemote()
    }
  }, [props.charityId])
  const [showVideo, setShowVideo] = useState(false)
  const [selectedItem, setSelectedItem] = useState<any>(null)
  if (records.length === 0) return null

  return (
    <>
      {showVideo && (
        <Modal show={showVideo} onHide={() => setShowVideo(false)} size="lg">
          <Modal.Body>
            <iframe
              style={{
                width: "100%",
                height: "100%",
                minHeight: "50vh",
              }}
              src={`https://www.youtube.com/embed/${getYoutubeId(
                selectedItem.path
              )}?enablejsapi=1`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </Modal.Body>
        </Modal>
      )}
      <Card className="mb-4">
        <Card.Body>
          <Carousel>
            {records.map((item, index) => (
              <Carousel.Item key={index}>
                <div
                  className="clickable"
                  onClick={() => {
                    setSelectedItem(item)
                    setShowVideo(true)
                  }}
                >
                  <img
                    style={{
                      objectFit: "contain",
                    }}
                    src={`https://img.youtube.com/vi/${getYoutubeId(
                      item.path
                    )}/maxresdefault.jpg`}
                  />
                  <button
                    className="btn clickable px-4 py-2"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 1,
                      backgroundColor: "orangeRed",
                      color: "white",
                    }}
                  >
                    <i className="fa-solid fa-play"></i>
                  </button>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Card.Body>
      </Card>
    </>
  )
}

export default CharityVideos
