import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons"
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from "react-bootstrap"
import { faLanguage } from "@fortawesome/free-solid-svg-icons"
import { Link, useHistory } from "react-router-dom"
import API from "../../common/API"
import { Routes } from "../../routes"
/* import BgImage from "../../assets/img/illustrations/signin.svg" */
import ClearUser from "../../common/ClearUser"
import Sha256 from "../../common/sha256"
import toast, { Toaster } from "react-hot-toast"
/* import logo from "../../assets/img/logoPleeloTexto.png" */
import { useTranslation } from "react-i18next"
import languages from "../../common/languages"
import * as yup from "yup"
import { useFormik } from "formik"
import Logo from "../Common/Logo"
import { AxiosResponse } from "axios"

export interface UserLogin {
  Email: string
  Password: string
}

const initialValues: UserLogin = {
  Email: "",
  Password: "",
}

export const Login = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [t, i18n] = useTranslation()
  const schema = yup.object({
    Email: yup
      .string()
      .required(t("Email is a required field"))
      .email(t("Email must be a valid email")),
    Password: yup
      .string()
      .required(t("Password is a required_field"))
      .min(6, t("Password must be at least 6 characters")),
  })

  useEffect(() => {
    ClearUser()
  }, [])

  const handleSubmit = async (values: UserLogin) => {
    try {
      let password = Sha256.hash(values.Password)
      let query = {
        username: values.Email,
        password: password,
      }
      setIsLoading(true)
      let request: AxiosResponse = await API.postAction(
        "account/RequestToken",
        query
      )
      let response = request.data
      localStorage.setItem("LogedUser", response.token)
      history.push(Routes.Home.path)
      //window.location = Routes.GetIn.clean + user.RefreshToken
    } catch (err) {
      toast.error(t("Userpass Invalid"))
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik<UserLogin>({
    initialValues: initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  const handlerSelectedLanguage = (lng: string) => {
    localStorage.setItem("lng", lng)
    i18n.changeLanguage(lng)
  }

  return (
    <>
      <Toaster />
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <div className="text-center">
              <Link to={Routes.Home.path}>
                <Logo
                  logoName="logo2"
                  alt="ibbundance"
                  className="navbar-brand-light mb-4"
                />
              </Link>
            </div>

            <Row
              className="justify-content-center form-bg-image"
              /* style={{ backgroundImage: `url(${BgImage})` }} */
            >
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group id="email" className="mb-4 ">
                          <Form.Label>{t("Email")} </Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>
                            <Form.Control
                              autoFocus
                              type="text"
                              name={"Email"}
                              value={formik.values.Email}
                              onChange={formik.handleChange}
                              placeholder={t("Email")}
                            />
                          </InputGroup>
                          <Form.Control.Feedback type="invalid">
                            {t("Email Invalid")}
                          </Form.Control.Feedback>
                          {formik.errors.Email ? (
                            <div className="invalid text-sm">
                              {formik.errors.Email}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Form.Group id="password" className="mb-4">
                        <Form.Label>{t("Password")}</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            type="password"
                            name={"Password"}
                            value={formik.values.Password}
                            onChange={formik.handleChange}
                            placeholder="••••••••"
                          />
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {t("Field Required")}
                        </Form.Control.Feedback>
                        {formik.errors.Password && (
                          <div className="invalid text-sm">
                            {formik.errors.Password}
                          </div>
                        )}
                      </Form.Group>
                      <div className="d-flex justify-content-between align-items-center mb-4 text-right">
                        <Card.Link
                          className="small text-end"
                          as={Link}
                          to={Routes.ForgotPassword.path}
                        >
                          {t("Recover Password")}
                        </Card.Link>
                      </div>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm mx-4"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : null}
                      {t("Login")}
                    </Button>
                  </Form>

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      {t("dont have account?")}&nbsp;
                      <Card.Link
                        as={Link}
                        to={Routes.Register.path}
                        className="fw-bold"
                      >
                        {t("Create account")}
                      </Card.Link>
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <FontAwesomeIcon
                      icon={faLanguage}
                      className="me-1 mt-1 fa-2x"
                    />
                    &nbsp;
                    <Form.Select
                      defaultValue={i18n.language}
                      onChange={e => handlerSelectedLanguage(e.target.value)}
                      size="sm"
                      className="mt-1 me-1"
                      style={{ width: "10em" }}
                    >
                      <option value="" disabled>
                        {t("Select language")}...
                      </option>
                      {languages.map(language => (
                        <option key={language.code} value={language.code}>
                          {language.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}
