import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import Timer from "../../Common/Timer"
import { ProgressBar } from "react-bootstrap"

interface ActionCardProps {
  companyId?: string
  record?: any
}

const ActionCard: FC<ActionCardProps> = props => {
  const { t } = useTranslation()

  const moneyCurrent = 250000
  const moneyGoal = 1000000

  return (
    <>
      <div className="bg-white rounded shadow">
        <div className="row p-3">
          <div className="mt-1 mb-3 d-grid">
            <p className="p-0 m-0">{t("Next raffle")}</p>
            <div className="display-4 text-primary my-0 ">
              {props.record && <Timer deadline={props.record.nextRaffle} />}
            </div>
            <div className="mb-1 ">
              <small className="text-muted poppins-medium">
                {`${t("IBBs")} ${moneyGoal}`}
              </small>
            </div>
            <button
              className="btn btn-secondary btn-lg mt-2"
              onClick={async () =>
                await navigator.share({
                  title: t("Share"),
                  text: t("Share"),
                  url: window.location.href,
                })
              }
            >
              {/* <i className="fa fa-share me-2"></i> */}
              {t("Share")}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ActionCard
