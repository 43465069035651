import { useTranslation } from "react-i18next"
import CRUD from "../../core/genericCRUD/CRUD"
import { FC } from "react"

interface CharityStatusProps {
  show: boolean
  toggle: (section: string) => void
}

const CharityStatusComponent: FC<CharityStatusProps> = ({ show, toggle }) => {
  const { t } = useTranslation()

  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={show}
      // modalSize="lg"
      toggleCRUD={() => toggle("")}
      title={t("Charity Status") ?? ""}
      getRecordsPath={`CharityStatus/list`}
      addEditRecordPath={`CharityStatus/AddEdit`}
      deleteRecordPath="CharityStatus/Delete"
      fieldsList={[
        // {
        //   name: "id",
        //   title: t("id"),
        // },

        {
          name: "name",
          title: t("name"),
        },
        {
          name: "color",
          title: t("color"),
          htmlType: "color",
          cssClass: "text-center",
        },
        {
          name: "default",
          title: t("default"),
          htmlType: "bool",
          cssClass: "text-center",
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "color",
          htmlType: "color",
          dbType: "string",
          title: t("color") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },
        {
          name: "default",
          htmlType: "bool",
          dbType: "bit",
          title: t("Default") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },
      ]}
      filterFields={[
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-4",
          required: false,
        },
      ]}
    />
  )
}

export default CharityStatusComponent
