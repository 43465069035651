import React, { useState } from "react"
import { Modal, Form, Row, Col } from "react-bootstrap"
import { useFormik } from "formik"
import * as yup from "yup"
import API from "../../common/API"
import { useTranslation } from "react-i18next"
import {
  AccountDetails,
  AccountDetailsResp,
  EditProfileProps,
} from "./profile.interface"
import { AxiosResponse } from "axios"

const EditProfile = ({ model, show, toggle }: EditProfileProps) => {
  const { t } = useTranslation()
  const [initialValues] = useState({ ...model })
  const [isLoading, setIsLoading] = useState(false)

  // console.log(props)
  const handleSubmit = async (obj: AccountDetails) => {
    setIsLoading(true)
    try {
      let body = {
        id: model.id,
        firstName: obj.firstName,
        lastName: obj.lastName,
        email: obj.email,
      }
      let requestAPI: AxiosResponse<AccountDetailsResp> = await API.postAction(
        "account/Edit",
        body
      )

      console.log(requestAPI)
      if (requestAPI.data.status === "ok") {
        toggle(true)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const validationSchema = yup.object({
    firstName: yup.string().required(t("Name is a required field")),
    lastName: yup.string().required(t("Last name is a required field")),
    email: yup
      .string()
      .required(t("Email is a required field"))
      .email(t("Email must be a valid email")),
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <Modal show={show} onHide={() => toggle(true)}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Profile")}</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12} md={6}>
              <label>{t("FirstName")}</label>
              <Form.Control
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
              {formik.errors.firstName && (
                <div className="invalid text-sm">{formik.errors.firstName}</div>
              )}
            </Col>
            <Col xs={12} md={6}>
              <label>{t("LastName")}</label>
              <Form.Control
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              {formik.errors.lastName && (
                <div className="invalid text-sm">{formik.errors.lastName}</div>
              )}
            </Col>
            <Col xs={12} md={12}>
              <label>{t("E-Mail")}</label>
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email && (
                <div className="invalid text-sm">{formik.errors.email}</div>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn btn-block btn-primary"
            type="submit"
            disabled={isLoading}
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm mx-4"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {t("Save")}
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditProfile
