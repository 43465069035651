import { useEffect, useState } from "react"
import { useFormik } from "formik"
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import * as yup from "yup"
import API from "../../common/API"
import Sha256 from "../../common/sha256"
import { Routes } from "../../routes"
import Logo from "../Common/Logo"

//translation
import { useTranslation } from "react-i18next"

const PasswordRecovery = props => {
  const { id } = useParams<{ id }>()
  const { t } = useTranslation()

  //Validation
  const validationSchema = yup.object({
    NewPassword: yup
      .string()
      .required(t("Password is a required field"))
      .min(6, t("Password must be at least 6 characters")),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], t("Passwords must match")),
  })

  const [isLoading, setIsLoading] = useState(false)

  const bindData = async () => {
    let request = await API.getAction("accounts/passwordRecovery?id=" + id)
    if (request.data.status === "ok") {
    } else {
      alert("Código no valido, será redireccionado")
      // window.location = Routes.SignIn.path
    }
  }

  const handleSubmit = async (obj, event) => {
    setIsLoading(!isLoading)

    let password = Sha256.hash(formik.values.NewPassword)
    let query = {
      Id: id,
      Password: password,
    }
    let request = await API.postAction("accounts/PasswordRecoveryApply", query)
    setIsLoading(false)
    console.log("response from adding", request)
    // window.location = Routes.SignIn.path
    if (request.status === 200) {
      // props.toggle(request.data.response, true);
    }
  }
  const formik = useFormik({
    initialValues: {
      NewPassword: "",
      PasswordReset: "",
      ConfirmPassword: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    bindData()
  }, [])

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <div className="text-center">
            <Link to={Routes.Home.path}>
              <Logo
                logoName="logo2"
                className="navbar-brand-light mb-4"
                alt="ibbundance"
              />
            </Link>
          </div>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h4 className="mb-0">{t("Recover Password")}</h4>
                </div>
                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col xs={12} className="mb-2">
                      <label>{t("newPassword")}</label>
                      <Form.Control
                        autoFocus
                        type="password"
                        name="NewPassword"
                        value={formik.values.NewPassword}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.NewPassword ? (
                        <div className="invalid text-sm">
                          {formik.errors.NewPassword}
                        </div>
                      ) : null}
                    </Col>
                    <Col xs={12} className="mb-2">
                      <label>{t("Conf. Password")}</label>
                      <Form.Control
                        type="password"
                        name="ConfirmPassword"
                        value={formik.values.ConfirmPassword}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.ConfirmPassword ? (
                        <div className="invalid text-sm">
                          {formik.errors.ConfirmPassword}
                        </div>
                      ) : null}
                    </Col>
                    <Col xs={12} className="mt-2">
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-100"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span
                            className="spinner-border spinner-border-sm mx-4"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}
                        {t("Recover Passsword")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    <Card.Link
                      as={Link}
                      to={Routes.SignIn.path}
                      className="fw-bold"
                    >
                      {t("Back to login")}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}

export default PasswordRecovery
