import { useFormik } from "formik"
import { useState } from "react"
import { Alert, Form, InputGroup, Modal } from "react-bootstrap"
import * as yup from "yup"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import Sha256 from "../../common/sha256"
import { ChangePasswordProps, ParsedUserInterface } from "./profile.interface"
import toast, { Toaster } from "react-hot-toast"
//Translation
import { useTranslation } from "react-i18next"
import { AxiosResponse } from "axios"

const initialValues = {
  OldPassword: "",
  NewPassword: "",
  RepeatPassword: "",
}

const ChangePassword = ({ show, toggle }: ChangePasswordProps) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [visibleAlert, setAlertVisible] = useState(false)

  const user: ParsedUserInterface = parsedUser()

  const validationSchema = yup.object({
    OldPassword: yup
      .string()
      .required(t("Field Required"))
      .min(6, t("Password must be at least 6 characters")),
    NewPassword: yup
      .string()
      .required(t("Field Required"))
      .min(6, t("Password must be at least 6 characters")),
    RepeatPassword: yup
      .string()
      .required(t("Field Required"))
      .oneOf([yup.ref("NewPassword"), null], t("Passwords must match")),
  })

  const handleSubmit = async (obj: typeof initialValues) => {
    try {
      setIsLoading(true)
      let query =
        "Email=" +
        user.Email +
        "&OldPassword=" +
        obj.OldPassword +
        "&NewPassword=" +
        Sha256.hash(obj.NewPassword)

      let request: AxiosResponse = await API.getAction(
        "account/ChangePassword",
        query
      )
      if (request.status === 200) {
        toast.success(t("Password changed successfully"))
        setAlertVisible(true)
        setTimeout(() => {
          toggle(true)
        }, 2000)
      }
    } catch (error) {
      toast.error(t("Something went wrong"))
      console.log("Error: ", error)
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
  })

  return (
    <>
      <Toaster />
      <Modal show={show} onHide={() => toggle(true)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa fa-lock " /> &nbsp; {t("Change Password")}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group id="oldPassword" className="mb-4">
              <InputGroup>
                <Form.Control
                  value={formik.values.OldPassword}
                  onChange={formik.handleChange}
                  name="OldPassword"
                  type="password"
                  placeholder={t("old password")}
                />
              </InputGroup>
              {formik.errors.OldPassword ? (
                <div className="invalid text-sm">
                  {formik.errors.OldPassword}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group id="NewPassword" className="mb-4">
              <InputGroup>
                <Form.Control
                  autoFocus
                  value={formik.values.NewPassword}
                  onChange={formik.handleChange}
                  name="NewPassword"
                  type="password"
                  placeholder={t("New Password")}
                />
              </InputGroup>
              {formik.errors.NewPassword ? (
                <div className="invalid text-sm">
                  {formik.errors.NewPassword}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group id="RepeatPassword" className="mb-4">
              <InputGroup>
                <Form.Control
                  autoFocus
                  value={formik.values.RepeatPassword}
                  onChange={formik.handleChange}
                  name="RepeatPassword"
                  type="password"
                  placeholder={t("Conf. Password")}
                />
              </InputGroup>
              {formik.errors.NewPassword ? (
                <div className="invalid text-sm">
                  {formik.errors.RepeatPassword}
                </div>
              ) : null}
            </Form.Group>
            <Alert show={visibleAlert} role="alert" variant="success">
              {t("Password changed successfully")}
            </Alert>
            <button
              className="btn btn-primary btn-block"
              type="submit"
              disabled={isLoading}
            >
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm mx-4"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              {t("Save")}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ChangePassword
