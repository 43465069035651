import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"

const Error404 = () => {
  const { t } = useTranslation()
  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <Row>
        <Col className="text-center">
          <h1 className="display-1">404</h1>
          <p className="lead">
            {t("Oops, the page you are looking for does not exist.")}
          </p>
          <a href="/" className="btn btn-primary">
            {t("Back to main page")}
          </a>
        </Col>
      </Row>
    </Container>
  )
}

export default Error404
