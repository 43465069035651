import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap"
import toast, { Toaster } from "react-hot-toast"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import * as yup from "yup"
import API from "../../common/API"
import ClearUser from "../../common/ClearUser"
import { Routes } from "../../routes"
import Logo from "../Common/Logo"

//translation
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

const InitialValues = {
  Email: "",
}

const ForgotPassword = () => {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const schema = yup.object({
    Email: yup
      .string()
      .required(t("Email is a required_field"))
      .email(t("Email must be a valid email")),
  })

  useEffect(() => {
    ClearUser()
  }, [])

  const handleSubmit = async (obj: typeof InitialValues) => {
    try {
      setIsLoading(true)
      await API.getAction(`Account/ForgotPassword?Email=${obj.Email}`)
      toast.success(t("Request received"))
      history.push(Routes.SignIn.path)
    } catch (err) {
      console.error("Error")
      toast.error(t("Something went wrong. Please try again later."))
    } finally {
      setIsLoading(false)
    }
  }

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  return (
    <>
      <Toaster />
      <main>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <Container>
            <div className="text-center my-4">
              <Link to={Routes.Home.path}>
                <Logo logoName="logo2" alt="logo" className="mb-4" />
              </Link>
            </div>

            <Row className="justify-content-center form-bg-image">
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h4 className="mb-0">{t("recover Password")}</h4>
                  </div>
                  <Form
                    className="mt-4 shadow-sm"
                    onSubmit={formik.handleSubmit}
                  >
                    <Form.Group id="email" className="mb-4 ">
                      <Form.Label>{t("Email")} </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          type="text"
                          name={"Email"}
                          placeholder={t("Email")}
                          value={formik.values.Email}
                          onChange={formik.handleChange}
                          isInvalid={!!formik.errors.Email}
                        />
                      </InputGroup>
                      {formik.errors.Email && (
                        <div className="invalid text-sm">
                          {formik.errors.Email}
                        </div>
                      )}
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <span
                          className="spinner-border spinner-border-sm mx-4"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : null}
                      {t("Recover Password")}
                    </Button>
                  </Form>

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <span className="fw-normal">
                      <Card.Link
                        as={Link}
                        to={Routes.SignIn.path}
                        className="fw-bold"
                      >
                        {t("Back Login")}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </>
  )
}

export default ForgotPassword
