import SettingSectionDTO from "./SettingSectionDTO"

const SettingSetions: SettingSectionDTO[] = [
  {
    name: "administration",
    parentId: "admin",
    requiredParent: false,
    sections: [
      // {
      //   reference: "users",
      //   label: "users",
      // },
      {
        reference: "roles",
        label: "Roles",
      },
      // { reference: "company", label: "Company" },
      // {
      //   reference: "foundation",
      //   label: "Foundation",
      // },
      {
        reference: "foundationCategory",
        label: "Foundation Category",
      },
      {
        reference: "charityStatus",
        label: "Charity Status",
      },
      {
        reference: "companyStatus",
        label: "Company Status",
      },
      {
        reference: "faq",
        label: "FAQ",
      },
      {
        reference: "reportCategory",
        label: "Report Category",
      },
      {
        reference: "reportStatus",
        label: "Report Status",
      },
      {
        reference: "socialNetwork",
        label: "Social Network",
      },
      {
        reference: "country",
        label: "Country",
      },
    ],
  },
]

export default SettingSetions
