import { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router"
import API from "../../../common/API"
import { Card, Col, Container, Dropdown, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { getBreakpointCurrent } from "../../../common/BreakPoints"
import { CompanyI } from "../../../common/interface/Companies.interface"
import { toast } from "react-toastify"
import Settings from "../../../common/Settings"
import { ensureHttpsUrl } from "../../../common/ensureHttpsUrl"
import ReportIssue from "../../Common/ReportIssue/ReportIssue"
import { dateFormater } from "../../../common/date-formater"
import ActionCard from "./ActionCard"
import CharityVideos from "../../CharitiesComponents/CharityDetails/CharityVideos"
import OtherCompanies from "./OtherCompanies"
import CharityPhotos from "../../CharitiesComponents/CharityDetails/CharityPhotos"
import CommentSection from "./CommentSection"
import AffiliatedFoundations from "./AffiliatedFoundations"
import { parsedUser } from "../../../common/GetCurrentUser"
import CompaniesAddEdit from "../CompaniesAddEdit/CompaniesAddEdit"
import ManageRaffle from "./ManageRaffle"

interface routeParams {
  id: string | undefined
}

const CompanyDetails = () => {
  const { t } = useTranslation()

  const { id } = useParams<routeParams>()
  const [showReport, setShowReport] = useState(false)
  const [breakpoint, setBreakpoint] = useState(getBreakpointCurrent())
  const [company, setCompany] = useState<CompanyI | undefined>()
  const [showAddEdit, setShowAddEdit] = useState(false)

  const [showManageRaffle, setShowManageRaffle] = useState(false)
  const location = useLocation()

  const user = parsedUser()

  const RequestCompanyDetails = async () => {
    try {
      let requestAPI = await API.getAction("company/details?id=" + id)

      if (requestAPI.status === 200) {
        setCompany(requestAPI.data.response)
      } else {
        throw new Error("Oops, thats a error?")
      }
    } catch (error) {
      toast.error(`${error}`)
    }
  }
  const toggleAddEdit = () => {
    setShowAddEdit(!showAddEdit)
  }
  const toggleReport = () => {
    setShowReport(!showReport)
  }
  const toggleRaffle = () => {
    setShowManageRaffle(!showManageRaffle)

    if (showManageRaffle) {
      RequestCompanyDetails()
    }
  }
  useEffect(() => {
    RequestCompanyDetails()
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getBreakpointCurrent())
    }

    window.addEventListener("resize", handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <>
      {showManageRaffle && (
        <ManageRaffle toggle={toggleRaffle} record={company} />
      )}
      {showAddEdit && (
        <CompaniesAddEdit toggle={toggleAddEdit} record={company} />
      )}
      {showReport && <ReportIssue toggle={toggleReport} />}
      <Container fluid className="py-5 bg-light mx-0">
        <Container>
          <Row className="my-4 ">
            <Col xs={12} md={8}>
              <Card className="bg-white mb-4">
                <Card.Body>
                  {/* HEADER */}
                  <Row>
                    <Col className="col-auto ">
                      <div
                        style={{ height: "14rem", width: "14rem" }}
                        className="clickableCard"
                      >
                        <img
                          src={`${Settings.BasePath}files/${company?.logo}`}
                          alt={company?.name}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "0.25rem",
                          }}
                          loading="lazy"
                        />
                      </div>
                    </Col>
                    <Col>
                      <h1 className="text-primary mb-0 poppins-medium d-inline">
                        {company?.name}
                      </h1>

                      <Dropdown className="d-inline ms-2 float-end">
                        <Dropdown.Toggle className="btn btn-clean clickable">
                          <i className="fa fa-ellipsis-v"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => toggleRaffle()}>
                            {t("Manage Raffle")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            href="#/action-1"
                            onClick={() => {
                              /*  toggleSocialMedia() */
                            }}
                          >
                            {t("Manage Social Media")}
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            {t("Manage Photos")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              /* toggleVideos() */
                            }}
                          >
                            {t("Manage Videos")}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              toggleReport()
                            }}
                          >
                            {t("Report this company")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <button
                        className="btn btn-clean ms-2 float-end"
                        onClick={() => {
                          toggleAddEdit()
                        }}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                      <br />
                      <small className="text-muted poppins-medium">
                        {dateFormater(company?.created)}
                      </small>

                      <div className=" d-flex align-items-end">
                        <div>
                          {/* {charity?.socialNetwork &&
                          charity?.socialNetwork.length > 0 &&
                          charity?.socialNetwork.map((socialNetwork, index) => {
                            return (
                              <a
                                className="clickable me-2"
                                key={index}
                                title={socialNetwork.path}
                                href={socialNetwork.path}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i
                                  className={`${socialNetwork.socialNetworkIcon} `}
                                ></i>
                              </a>
                            )
                          })}
                        <br /> */}
                          <span className="text-secondary opacity-8 poppins-medium">
                            {t("Website")}:{" "}
                          </span>
                          <a
                            href={ensureHttpsUrl(company?.website)}
                            className="text-primary"
                            style={{ textDecoration: "underline" }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {company?.website}
                          </a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="p-2">
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item mb-0" role="presentation">
                      <button
                        className="nav-link bg-primary text-light active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        {t("About")}
                      </button>
                    </li>
                    {/* <li className="nav-item  mb-0" role="presentation">
                      <button
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Words of Support
                      </button>
                    </li> */}
                  </ul>
                </Card.Footer>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <ActionCard record={company} />
            </Col>

            <Col xs={12} md={8}>
              {/* ABOUT */}
              <Card className="mb-4">
                <Card.Header className="poppins-bold">
                  {t("About Company")}
                </Card.Header>
                <Card.Body>
                  <div>
                    <p>{company?.description}</p>
                  </div>
                </Card.Body>
              </Card>

              {/* VIDEOS */}
              <CharityVideos charityId={company?.id} />
              <Card className="mb-4">
                <Card.Header className="poppins-bold">
                  {t("Photos")}
                </Card.Header>
                <Card.Body>
                  <CharityPhotos charityId={company?.id} />
                </Card.Body>
              </Card>
              {/* Affiliated Foundations */}
              <AffiliatedFoundations companyId={company?.id} />
              {user ? (
                <Card className="mb-4">
                  <Card.Body>
                    <CommentSection />
                  </Card.Body>
                </Card>
              ) : (
                ""
              )}

              <OtherCompanies charityId={company?.id} />
            </Col>

            {/* <Col xs={12} md={4}> */}
            {/* IMAGES */}
            {/* <Card className="mb-4">
              <Card.Header className="poppins-bold">{t("Photos")}</Card.Header>
              <Card.Body>
                <CharityPhotos charityId={company?.id} />
              </Card.Body>
            </Card> */}
            {/* </Col> */}
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default CompanyDetails
