export const ensureHttpsUrl = (url: string | undefined): string | undefined => {
  // Si la URL no comienza con 'http://' o 'https://', agrega 'https://'
  if (url) {
    if (!url.startsWith("http://") && !url.startsWith("https://"))
      return `https://${url}`

    // Si la URL comienza con 'http://', puedes reemplazarlo con 'https://'
    /*  if (url.startsWith("http://")) return url.replace("http://", "https://") */
  }

  // Si ya comienza con 'https://', retorna la URL sin cambios
  return url
}
