import HeroImage from "./HeroImage"
import PromotedFoundationsComponent from "./PromotedFoundationsComponent"
import { Container } from "react-bootstrap"

import FaqComponent from "./FaqComponent"
import CompaniesViewList from "./CompaniesViewList"
import TextAndSlideShowContainer from "./TextAndSlideShowContainer"
import { useTranslation } from "react-i18next"

const HomeComponent = () => {
  const { t } = useTranslation()

  return (
    <div className="bg-main">
      <div className="d-none d-md-block">
        <HeroImage />
      </div>
      <Container>
        <CompaniesViewList
          title={t("Upcoming Raffles")}
          hideAllEntity={true}
          hideCouldDown={false}
        />
        <PromotedFoundationsComponent />

        <CompaniesViewList title="Companies" hideAllEntity={false} />

        <div className="py-2 py-md-3 py-lg-4" />

        <TextAndSlideShowContainer />
      </Container>
      <Container
        className="py-4 d-flex justify-content-center mb-4 separateSlideShow"
        style={{ marginTop: "400px" }}
      ></Container>
      <FaqComponent />
    </div>
  )
}

export default HomeComponent
