import { AbstractName } from "../../../common/helpers"

const ProfilePicture = ({ user }) => {
  let fullName: string = user.FirstName + " " + user.LastName
  return (
    <div className="media d-flex align-items-center justify-content-center bg-light px-2 py-2 rounded-5">
      <div className="user-avatar rounded bg-primary rounded-5">
        <b>{AbstractName(fullName)}</b>
      </div>
      <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
        <span className="mb-0 font-small fw-bold">{user && fullName}</span>
        <i className="ms-2 fa-solid fa-chevron-down text-primary" />
      </div>
    </div>
  )
}

export default ProfilePicture
