import React from "react"
import CRUD from "../../core/genericCRUD/CRUD"
import { useTranslation } from "react-i18next"

const ManageVideos = props => {
  const { t } = useTranslation()
  return (
    <>
      {" "}
      <CRUD
        // cssClass="modalRight"
        isModal={true}
        showModal={true}
        // modalSize="sm"
        // modalSize="lg"
        toggleCRUD={() => props.toggle("")}
        title={t("Videos") ?? ""}
        parentId="charityId"
        parentValue={props.charityId}
        getRecordsPath={`charityVideo/list`}
        addEditRecordPath={`charityVideo/AddEdit`}
        deleteRecordPath="charityVideo/Delete"
        fieldsList={[
          // {
          //   name: "id",
          //   title: t("id"),
          // },
          {
            name: "title",
            title: t("Title"),
          },
          {
            name: "path",
            title: t("Path"),
            cssClass: "text-wrap",
          },
        ]}
        fields={[
          {
            name: "id",
            htmlType: "hidden",
            dbType: "string",
          },

          {
            name: "title",
            htmlType: "text",
            dbType: "string",
            title: t("title") ?? "",
            containerClass: "col-12 col-md-6",
            required: true,
          },

          {
            name: "path",
            htmlType: "text",
            dbType: "string",
            title: t("Path") ?? "",
            containerClass: "col-12 col-md-6",
            required: true,
          },
        ]}
        filterFields={[
          {
            name: "title",
            htmlType: "text",
            dbType: "string",
            title: t("title") ?? "",
            containerClass: "col-12 col-md-4",
            required: false,
          },
        ]}
      ></CRUD>
    </>
  )
}

export default ManageVideos
