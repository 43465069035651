import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../../core/genericCRUD/CRUD"
import { parsedUser } from "../../../common/GetCurrentUser"
import API from "../../../common/API"
import { Card, Col, Container, Row } from "react-bootstrap"
import MostVisitedCharity from "./MostVisitedCharity"
import MostVisitedCompany from "./MostVisitedCompany"

const DashboardComponent = props => {
  const { t } = useTranslation()
  const user = parsedUser()
  const [general, setGeneral] = useState<any>({})
  const bindDataDashboard = async () => {
    let request = await API.getAction("dashboard/general")
    console.log("requesr general", request)
    if (request.data.status === "ok") {
      setGeneral(request.data.response)
    }
  }

  useEffect(() => {
    bindDataDashboard()
  }, [])

  return (
    <Container>
      <Row>
        <Col>
          <Card className="mb-4">
            <Card.Header className="poppins-bold">
              <Card.Title>{general && general.charities}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>{t("Foundations")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="mb-4">
            <Card.Header className="poppins-bold">
              <Card.Title>{general && general.companies}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>{t("Companies")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="mb-4">
            <Card.Header className="poppins-bold">
              <Card.Title>{general && general.reports}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>{t("Reports")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="mb-4">
            <Card.Header className="poppins-bold">
              <Card.Title>{general && general.users}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>{t("Users")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="mb-4 ">
            <Card.Header className="poppins-bold">
              <Card.Title>{general && general.donations}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>{t("Donations")}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <MostVisitedCharity />
        </Col>
        <Col xs={12} md={6}>
          <MostVisitedCompany />
        </Col>
      </Row>
    </Container>
  )
}

export default DashboardComponent
