import { useTranslation } from "react-i18next"
import CRUD from "../../core/genericCRUD/CRUD"
import { FC } from "react"

interface ReportCategoryProps {
  show: boolean
  toggle: (section: string) => void
}

const ReportCategoryComponent: FC<ReportCategoryProps> = ({ show, toggle }) => {
  const { t } = useTranslation()

  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={show}
      // modalSize="lg"
      toggleCRUD={() => toggle("")}
      title={t("Report Category") ?? ""}
      getRecordsPath={`reportCategory/list`}
      addEditRecordPath={`reportCategory/AddEdit`}
      deleteRecordPath="reportCategory/Delete"
      fieldsList={[
        // {
        //   name: "id",
        //   title: t("id"),
        // },

        {
          name: "name",
          title: t("name"),
        },
        {
          name: "icon",
          title: t("icon"),
          htmlType: "icon",
          cssClass: "text-center",
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "icon",
          htmlType: "text",
          dbType: "string",
          title: t("icon") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
      ]}
      filterFields={[
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-4",
          required: false,
        },
      ]}
    />
  )
}

export default ReportCategoryComponent
