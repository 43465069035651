import { Card } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Routes } from "../../../routes"
import { useTranslation } from "react-i18next"

/* i18next-extract-disable */
interface ItemProps {
  id: number
  title: string
  img: string
  progress: number
  raised: number
  imgFooter: string
  customWidth?: string
  customHeight?: string
  main?: boolean
}

/* i18next-extract-enable */

const Item = ({
  id,
  title,
  img,

  raised,

  customWidth,
  customHeight,
  main,
}: ItemProps) => {
  const { t } = useTranslation()

  return (
    <Link to={`${Routes.FoundationDetails.clean}${id}`} key={id}>
      <Card
        className="causeItem pb-4 pt-2 px-2 "
        style={{ width: `${customWidth ?? "380px"}` }}
      >
        <Card className="bg-dark text-white">
          <Card.Img
            src={img}
            style={{
              objectFit: "contain",
              height: main ? "auto" : "215px",
            }}
            alt={t("Card image")}
            height={customHeight ?? "auto"}
          />
          {/* <Card.ImgOverlay className="d-flex justify-content-start h-100 align-items-end p-3">
            <Card.Text>
              <Badge
                pill
                bg="transparent"
                className="py-1 px-2 bg-primary-alpha-60"
              >
                <span className="lead text-white" style={{ fontSize: "12px" }}>
                  {`${imgFooter}`}
                </span>
              </Badge>
            </Card.Text>
          </Card.ImgOverlay> */}
        </Card>
        <div>
          <h6 className="mt-2 poppins-bold">{t(title)}</h6>
          {/* <ProgressBar now={progress} variant="secondary" /> */}
          <div className="d-flex justify-content-between mt-2">
            <span
              className="lead"
              style={{ fontSize: "12px" }}
            >{`$${raised} ${t("raised")}`}</span>
          </div>
        </div>
      </Card>
    </Link>
  )
}

export default Item
