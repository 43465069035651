import { FC } from "react"
import { PaginationI } from "../../common/interface/Pagination.interface"
import { useTranslation } from "react-i18next"

interface ButtonLoadMoreProps {
  increaseCurrentQuantity: () => void
  paginationState: PaginationI
}

const ButtonLoadMore: FC<ButtonLoadMoreProps> = ({
  increaseCurrentQuantity,
  paginationState,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {paginationState.quantityOfRecords > 0 &&
        paginationState.currentQuantity < paginationState.quantityOfRecords && (
          <button
            className="load-more-btn rounded"
            onClick={() => increaseCurrentQuantity()}
          >
            {t("Load more")} <i className="ms-2 fa-solid fa-chevron-down" />
          </button>
        )}
    </>
  )
}

export default ButtonLoadMore
