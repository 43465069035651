import { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CharitiesFilter from "./CharitiesFilter/CharitiesFilter"
import CharitiesList from "./CharitiesList/CharitiesList"
import Loading from "../Loading"

import { CharityI } from "../../common/interface/CharityI"

import { useTranslation } from "react-i18next"
import CharitiesSkeletonList from "./Skeletons/CharitiesSkeletonList"
import Skeleton from "react-loading-skeleton"

const CharitiesContainer = () => {
  const [charities, setCharities] = useState<CharityI[] | undefined>([])

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  const toggleLoading = (status: boolean) => setLoading(status)

  return (
    <Container className="py-5 ">
      <Row>
        <Col xs={12} className="mb-5">
          <div>
            <h1 className="text-primary display-1">
              {loading ? (
                <Skeleton width={300} height={60} />
              ) : (
                t("The foundations we are partnered with")
              )}
            </h1>
            {/* <span className="text-primary" style={{ fontSize: "4rem" }}>
                The companies we are partnered with
              </span> */}
          </div>
          <span className="text-muted fs-2">
            {loading ? (
              <Skeleton width={400} />
            ) : (
              t(
                "People globally are fundraising for the causes they care about deeply."
              )
            )}
          </span>
        </Col>
        {/* FILTER */}
        <CharitiesFilter
          isLoading={loading}
          toggleLoading={toggleLoading}
          setCharities={setCharities}
        >
          {/* CONTENT */}

          {loading ? (
            <CharitiesSkeletonList />
          ) : (
            <>
              <CharitiesList charities={charities} />
            </>
          )}
        </CharitiesFilter>
      </Row>
    </Container>
  )
}

export default CharitiesContainer
