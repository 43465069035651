import { ComponentType, FC, ReactNode } from "react"

interface StructureProps {
  element: ComponentType
  layout: ComponentType<{ children: ReactNode }>
}

const Structure: FC<StructureProps> = ({
  element: Element,
  layout: Layout,
}) => {
  return (
    <Layout>
      <Element />
    </Layout>
  )
}

export default Structure
