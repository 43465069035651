import { useTranslation } from "react-i18next"
import CRUD from "../../../core/genericCRUD/CRUD"
import { FC } from "react"
import { parsedUser } from "../../../../common/GetCurrentUser"

interface CountryComponentProps {
  show: boolean
  toggle: (sectionName: string) => void
}

const CountryComponent: FC<CountryComponentProps> = ({ show, toggle }) => {
  const { t } = useTranslation()

  const user = parsedUser()

  console.log(user)

  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={show}
      // modalSize="lg"
      toggleCRUD={() => toggle("")}
      title={t("Countries") ?? ""}
      getRecordsPath={`country/list`}
      addEditRecordPath={`country/AddEdit`}
      deleteRecordPath={`country/Delete`}
      fieldsList={[
        /*         {
          name: "id",
          title: t("id"),
        }, */

        {
          name: "name",
          title: t("name"),
        },
        {
          name: "icon",
          title: t("icon"),
          htmlType: "icon",
          cssClass: "text-center",
        },
        {
          name: "position",
          title: t("position"),
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "icon",
          htmlType: "text",
          dbType: "string",
          title: t("icon") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "position",
          htmlType: "number",
          dbType: "int",
          title: t("position") ?? "",
          containerClass: "col-12 col-md-6",
          required: false,
        },
      ]}
      filterFields={[
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-4",
          required: false,
        },
      ]}
    />
  )
}

export default CountryComponent
