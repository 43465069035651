import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { faqI } from "../../common/interface/Faq.interface"
import { Link } from "react-router-dom"
import LinkSeeMore from "../Common/LinkSeeMore"
import API from "../../common/API"
import { useTranslation } from "react-i18next"
import Skeleton from "react-loading-skeleton"

const FaqComponent = () => {
  const [faq, setFaq] = useState([])
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const handleToggle = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index)
  }

  const requestFAQ = async () => {
    try {
      setIsLoading(true)
      let queryString = `name=${""}&Quantity=${3}&Page=${0}`
      let requestAPI = await API.getAction("faq/pinned?" + queryString)

      setFaq(requestAPI.data.response)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    requestFAQ()
  }, [])

  return (
    <Container
      className="mt-4 py-4 d-flex justify-content-center"
      style={{ cursor: "pointer" }}
    >
      <div className="px-md-4 px-lg-5 pt-2 pt-md-4 pt-lg-5 mb-4 pb-md-4 pb-lg-5 pb-2">
        <div className="px-4 md:px-5 pt-2 md:pt-4 lg:pt-5 mb-4 md:mb-2 d-flex justify-content-between gap-5">
          <div>
            <h2 className="poppins-bold titles">
              {isLoading ? (
                <Skeleton width={200} height={30} />
              ) : (
                t("Do you need help?")
              )}
            </h2>
            <p className="poppins-regular">
              {isLoading ? (
                <Skeleton width={250} height={20} />
              ) : (
                t("Search our frequently asked questions")
              )}
            </p>
          </div>
        </div>
        {isLoading
          ? [...Array(3)].map(
              (
                _,
                index // Ajusta el número de skeletons según sea necesario
              ) => (
                <div
                  key={index}
                  className="px-4 md:px-5 pt-2 md:pt-4 lg:pt-5 mb-4 md:mb-2 d-flex justify-content-between gap-5"
                >
                  <div>
                    <Skeleton width={150} height={24} />
                    <Skeleton count={2} />
                  </div>
                  <div className="flex items-end">
                    <Skeleton width={50} height={30} />
                  </div>
                </div>
              )
            )
          : faq.map((item: faqI, index) => (
              <div
                key={index}
                className="px-4 md:px-5 pt-2 md:pt-4 lg:pt-5 mb-4 md:mb-2 d-flex justify-content-between align-items-center gap-5"
                style={{ maxWidth: "54rem" }}
                onClick={() => handleToggle(index)}
              >
                <div>
                  <h2 className="font-bold poppins-bold titles">
                    {item.title}
                  </h2>
                  <p className="poppins-regular">
                    {expandedIndex === index
                      ? item.content
                      : item.content.slice(0, 94) +
                        (item.content.length > 94 ? " . . ." : "")}
                  </p>
                </div>
                <div className="flex items-end">
                  <Link to={"#"} className="btn-more-info">
                    <i
                      className={`fa-solid ${
                        expandedIndex === index
                          ? "fa-chevron-down"
                          : "fa-chevron-right"
                      }  text-primary`}
                    />
                  </Link>
                </div>
              </div>
            ))}
        {isLoading ? (
          <Skeleton width={150} height={20} />
        ) : (
          <LinkSeeMore
            route="/faq"
            text={t("See more FAQs")}
            positionButton="center"
          />
        )}
      </div>
    </Container>
  )
}

export default FaqComponent
