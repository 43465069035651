import { useTranslation } from "react-i18next"
import CRUD from "../../../core/genericCRUD/CRUD"
import { FC } from "react"
import { parsedUser } from "../../../../common/GetCurrentUser"

interface CompanyComponentProps {
  show: boolean
  toggle: (sectionName: string) => void
}

const CompanyComponent: FC<CompanyComponentProps> = ({ show, toggle }) => {
  const { t } = useTranslation()

  const user = parsedUser()

  console.log(user)

  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={show}
      // modalSize="lg"
      toggleCRUD={() => toggle("")}
      title={t("Companies") ?? ""}
      getRecordsPath={`company/list`}
      addEditRecordPath={`company/AddEdit`}
      deleteRecordPath={`company/Delete`}
      fieldsList={[
        // {
        //   name: "id",
        //   title: t("id"),
        // },

        {
          name: "name",
          title: t("name"),
        },

        {
          name: "logo",
          title: t("logo"),
          htmlType: "picture",
          cssClass: "text-center",
        },
        {
          name: "website",
          title: t("website"),
        },
        {
          name: "phone",
          title: t("phone"),
        },
        /* {
          name: "ownerId",
          title: t("owner"),
        }, */
        {
          name: "companyStatusId",
          title: t("status"),
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "name",
          htmlType: "text",
          placeHolder: t("What's your company name?"),
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },

        {
          name: "phone",
          htmlType: "text",
          placeHolder: t("What's your company phone?"),
          dbType: "string",
          title: t("phone") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "logo",
          htmlType: "picture",
          dbType: "blob",
          title: t("logo") ?? "",
          typeFile: "image/*",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "ownerId",
          htmlType: "hidden",
          dbType: "string",
          value: user.ID,
        },
        {
          name: "description",
          htmlType: "textarea",
          placeHolder: t("talk about your company"),
          dbType: "string",
          title: t("description") ?? "",
          containerClass: "col-12",
          required: true,
        },
        {
          name: "website",
          htmlType: "text",
          placeHolder: t("Enter your URL website"),
          dbType: "string",
          title: t("website") ?? "",
          containerClass: "col-12",
          required: true,
        },
      ]}
      filterFields={[
        {
          name: "name",
          htmlType: "text",
          dbType: "string",
          title: t("name") ?? "",
          containerClass: "col-12 col-md-4",
          required: false,
        },
      ]}
    />
  )
}

export default CompanyComponent
