import { useTranslation } from "react-i18next"
import CRUD from "../../../core/genericCRUD/CRUD"
/* import { parsedUser } from "../../../../common/GetCurrentUser"
 */ import { FC } from "react"

interface FaqComponentProps {
  show: boolean
  toggle: (sectionName: string) => void
}

const FaqComponent: FC<FaqComponentProps> = ({ show, toggle }) => {
  const { t } = useTranslation()
  /* const user = parsedUser() */
  return (
    <CRUD
      cssClass="modalRight"
      isModal={true}
      showModal={show}
      toggleCRUD={() => toggle("")}
      title={t("FAQ") ?? ""}
      getRecordsPath={`faq/list`}
      addEditRecordPath={`faq/AddEdit`}
      deleteRecordPath="faq/Delete"
      fieldsList={[
        {
          name: "title",
          title: t("title"),
        },
      ]}
      fields={[
        {
          name: "id",
          htmlType: "hidden",
          dbType: "string",
        },
        {
          name: "title",
          htmlType: "text",
          dbType: "string",
          title: t("title") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          name: "content",
          htmlType: "text",
          dbType: "string",
          title: t("content") ?? "",
          containerClass: "col-12 col-md-6",
          required: true,
        },
        {
          title: "Pin",
          name: "separator",
          dbType: "",
          htmlType: "separator",
        },
        {
          name: "position",
          htmlType: "number",
          dbType: "number",
          title: t("position") ?? "",
          containerClass: "col-6",
          required: false,
          minNumber: 0,
          maxNumber: 3,
        },
        {
          name: "pinned",
          htmlType: "bool",
          dbType: "bool",
          title: t("pinned") ?? "",
          containerClass: "col-6 ",
          required: false,
        },
      ]}
    />
  )
}

export default FaqComponent
