import React from "react"
import { Col, Row } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"

const CharitiesSkeletonList = () => {
  return (
    <Row>
      {Array(10)
        .fill({})
        .map((_, index) => (
          <Col
            key={index}
            xs={12}
            md={6}
            lg={4}
            className={`mb-6  pb-5 clickableCard`}
            style={{ height: "18.75rem" }}
          >
            <Skeleton height={"100%"} />
            <Skeleton width={100} className="mt-2" />
            <Row>
              <Col>
                <Skeleton width={120} />
              </Col>
              <Col className="text-end">
                <Skeleton width={120} />
              </Col>
            </Row>
          </Col>
        ))}
    </Row>
  )
}

export default CharitiesSkeletonList
