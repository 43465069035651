import { useEffect } from "react"
import { defaultDataDB } from "./common/db"
import { Switch } from "react-router-dom"
import ErrorBoundary from "./components/Common/BoundaryError"

import routesWithComponent from "./pages/routesWithComponent"
import Structure from "./components/Common/Layouts/Structure"
import ValidateTypeRoute from "./components/core/ValidateTypeRoute"

import { Route } from "react-router"
import Error404 from "./components/Common/Error404"
import ScrollToTopOnRouteChange from "./components/Common/ScrollToTopOnRouteChange"

import { ToastContainer } from "react-toastify"

const App = () => {
  useEffect(() => {
    defaultDataDB()
  }, [])

  return (
    <ErrorBoundary>
      <ToastContainer />
      <ScrollToTopOnRouteChange />
      <Switch>
        {routesWithComponent.map((item, index) => {
          return (
            <Route
              key={index}
              path={item.path}
              exact={item.exact} // Asegúrate de agregar exact si es necesario
              render={props => (
                <ValidateTypeRoute typeRoute={item.typeRoute}>
                  <Structure
                    element={item.component}
                    layout={item.layout}
                    {...props}
                  />
                </ValidateTypeRoute>
              )}
            />
          )
        })}
        <Route component={Error404} />
      </Switch>
    </ErrorBoundary>
  )
}

export default App
