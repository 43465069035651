import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

// core styles
import "./scss/volt.scss"

//Init Translation Library
import "./i18n"

// vendor styles
// import "@fortawesome/fontawesome-free/css/all.css";
// import broken;
// App Styles
import "./css/app.css"
import "./css/FontPoppins.css"
import "react-toastify/dist/ReactToastify.css"
import "photoswipe/dist/photoswipe.css"
import "react-loading-skeleton/dist/skeleton.css"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import App from "./App"

import { createRoot } from "react-dom/client"

// Se asegura que el contenedor no sea null
const container = document.getElementById("root")

if (container) {
  const root = createRoot(container as HTMLElement)

  root.render(
    <Router>
      <App />
    </Router>
  )
} else {
  console.error("El contenedor 'root' no fue encontrado.")
}

// <Route path="/" component={Islogin() ? App : Login} exact/>
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

serviceWorkerRegistration.unregister()
