import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface LinkSeeMoreProps {
  route: string
  text: string
  statusIcon?: boolean
  iconFa?: string
  positionButton?: "start" | "center" | "end"
}

const LinkSeeMore: FC<LinkSeeMoreProps> = ({
  route,
  text,
  statusIcon = true,
  iconFa = "fas fa-arrow-right",
  positionButton = "end",
}) => {
  const { t } = useTranslation()
  return (
    <div className={`py-4 w-100 d-flex justify-content-${positionButton}`}>
      <Link to={`${route}`} className="load-more-btn rounded">
        {t(text)}
        {statusIcon ? <i className={` ${iconFa} ms-2`} /> : ""}
      </Link>
    </div>
  )
}

export default LinkSeeMore
