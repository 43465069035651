//We are settings an initial state here for clarity, probably is not needed

const Settings = {
  App: "ibbundance",
  Name: "ibbundance",

  baseURl: "./",
  BasePathForAPI: "https://api.ibbundance.com/api/",
  BasePath: "https://api.ibbundance.com/",
  AppURL: "https://ibbundance.com/",
  // BasePathForAPI: "https://localhost:44322/api/",
  // BasePath: "https://localhost:44322/",

  SocketPath: "",
  SyncFusionKey: ""
}

export default Settings


