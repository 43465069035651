import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { resources } from "./locales/resources"

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en-US",
  lng: localStorage.getItem("lng") || "en-US",
  interpolation: {
    escapeValue: false,
  },
  debug: false,
  saveMissing: true,
})

export default i18n
