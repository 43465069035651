import React, { useEffect, useState } from "react"

import API from "../../../common/API"

//Translation
import { useTranslation } from "react-i18next"
import { Col, Row } from "react-bootstrap"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"

import moment from "moment"
import { FormatMoney } from "../../../common/helpers"
import Settings from "../../../common/Settings"

const MostVisitedCompany = () => {
  const [from, setFrom] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  )
  const [state, setState] = useState<any>()
  const [until, setUntil] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  )

  const [totalAmount, setTotalAmount] = useState<any>(0)
  const [viewMode, setViewMode] = useState("graphic")
  const [records, setRecords] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation("global")

  const request = async () => {
    let Query = ""
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("dashboard/MostVisitedCompany", Query)

    if (requestAPI.data.status === "ok") {
      let result = requestAPI.data.response
      setRecords(result)

      let labels: string[] = []
      let total = 0
      result.forEach(item => {
        labels.push(item.name)
        total += item.visits
      })

      setTotalAmount(total)
      setState({
        labels,
        datasets: [
          {
            label: t("total"),
            data: result.map(x => x.visits),
            backgroundColor: "#4081d0",
          },
        ],
      })
    }
  }
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: t("Most Visited Companies"),
      },
    },
  }

  useEffect(() => {
    request()
  }, [])

  if (!records) {
    return <></>
  }

  if (!state) {
    return <></>
  }

  return (
    <>
      <div className="card shadow-sm mb-2">
        <div className="card-header">
          <Row>
            <Col className="mt-2">
              <span className="card-title display-6">
                {t("Most Visited Companies")}
              </span>
            </Col>

            <Col className="ms-auto col-auto mt-1 ">
              <div className="btn-group ">
                <button
                  className={
                    "btn btn-sm " +
                    (viewMode === "graphic" ? "btn-primary" : "")
                  }
                  type="button"
                  onClick={() => setViewMode("graphic")}
                >
                  <i className="fa fa-chart-line"></i>
                </button>
                <button
                  className={
                    "btn btn-sm " + (viewMode === "list" ? "btn-primary" : "")
                  }
                  type="button"
                  onClick={() => setViewMode("list")}
                >
                  <i className="fa fa-list"></i>
                </button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="card-body">
          <div className="row">
            {isLoading ? (
              <div className="col text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">{t("loading")}...</span>
                </div>
              </div>
            ) : null}
            <div className="col-12">
              {viewMode === "graphic" ? (
                <Bar options={options} data={state} />
              ) : (
                <table className="table table-stripped">
                  <thead>
                    <tr>
                      <th>{t("Name")}</th>
                      <th>{t("Visits")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Row>
                              <Col className="col-auto">
                                <img
                                  src={`${Settings.BasePath}files/${item.logo}`}
                                  alt={item.name}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "contain",
                                    borderRadius: "0.25rem",
                                  }}
                                  loading="lazy"
                                />
                              </Col>
                              <Col>{item.name}</Col>
                            </Row>
                          </td>
                          <td>{item.visits ?? "-"}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MostVisitedCompany
