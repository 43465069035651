import { useEffect, FC } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTopOnRouteChange: FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [pathname])

  return null
}

export default ScrollToTopOnRouteChange
