import { Container } from "react-bootstrap"
import AboutContainer from "../../components/AboutComponents/AboutContainer"

const AboutPage = () => {
  return (
  <Container>
    <AboutContainer />
  </Container>
)
}

export default AboutPage
