import { useEffect, useState } from "react"
import { Card, Col, Container, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../../common/API"
import Settings from "../../../common/Settings"
import Timer from "../../Common/Timer"

const ManageRaffle = props => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  console.log("manage goals", props)
  const [company, setCharity] = useState<any>(props.record)

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      let query =
        "companyId=" + company.id + "&nextRaffle=" + company.nextRaffle
      const requestAPI = await API.getAction("company/AssignRaffle", query)
      if (requestAPI.data.status === "ok") {
        props.toggle()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    // if (props.id) requestCompanies()
  }, [props.id])
  return (
    <Modal show={true} onHide={props.toggle} centered>
      <Modal.Header>
        <Modal.Title>{t("Manage Next Raffle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={12}>
              <label>{t("Date")}</label>
              <input
                type="datetime-local"
                name="nextRaffle"
                className="form-control"
                value={company.nextRaffle ?? ""}
                onChange={e => {
                  let value = e.target.value
                  setCharity({ ...company, nextRaffle: value })
                }}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {isLoading && (
          <div className="me-4 d-inline-block">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <button
          className="btn btn-secondary"
          onClick={props.toggle}
          disabled={isLoading}
        >
          {t("Cancel")}
        </button>
        <button
          className="btn btn-primary"
          disabled={isLoading}
          onClick={() => handleSubmit()}
        >
          {t("Save")}
        </button>
      </Modal.Footer>

      {/* </Modal.Dialog> */}
    </Modal>
  )
}

export default ManageRaffle
