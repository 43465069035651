import { RoutesI } from "./common/interface/RouteIs"

export const Routes: RoutesI = {
  // GENERIC
  getin: {
    path: "/getin/:id",
    clean: "/getin/",
  },

  //Admin Routes
  AdminUsers: { path: "/admin/users" },
  AdminOrganizations: { path: "/admin/organizations" },

  //Landing Page Routes
  Home: { path: "/" },
  SignIn: { path: "/signin" },
  ForgotPassword: { path: "/forgotPassword" },
  Register: { path: "/register" },
  PasswordRecovery: {
    path: "/passwordRecovery/:id",
    clean: "/passwordRecovery/",
  },

  //User Routes
  NotFound: { path: "/error/NotFound" },
  ServerError: { path: "/examples/ServerError" },
  Chat: { path: "/Chat" },
  Settings: { path: "/settings", clean: "/settings/" },
  Reports: { path: "/reports/:id" },
  Profile: { path: "/profile" },

  Foundations: { path: "/foundations", clean: "/foundations" },
  FoundationDetails: { path: "/foundations/:id", clean: "/foundations/" },
  FoundationSettings: {
    path: "/settings/foundation",
    clean: "/settings/foundation",
  },

  Faq: { path: "/faq", clean: "/faq" },

  Companies: { path: "/companies", clean: "/companies" },
  CompanyDetails: { path: "/companies/:id", clean: "/companies/" },

  CompaniesSettings: {
    path: "/settings/companies",
    clean: "/settings/companies",
  },

  About: { path: "/about", clean: "/about" },

  Search: { path: "/s" },
  Admin: { path: "/admin/:id?", clean: "/admin/" },
}
