import HomeComponent from "../../components/HomeComponents/HomeComponent"


const HomePage = () => {

  return (
   <HomeComponent/>
  )
}

export default HomePage
