import { FC, ReactNode } from "react"
import { parsedUser } from "../../common/GetCurrentUser"
import Error404 from "../Common/Error404"

interface ValidateTypeRouteProps {
  children: ReactNode
  typeRoute: string
}

const ValidateTypeRoute: FC<ValidateTypeRouteProps> = ({
  children,
  typeRoute,
}) => {
  const user = parsedUser()

  if (
    ["all"].includes(typeRoute) ||
    (user !== null && ["private"].includes(typeRoute)) ||
    ["public"].includes(typeRoute)
  )
    return children
  else return <Error404 />
}

export default ValidateTypeRoute
