export const getBreakpointCurrent = () => {
  const width = window.innerWidth

  if (width < 576) {
    return "xs" // Extra small
  } else if (width >= 576 && width < 768) {
    return "sm" // Small
  } else if (width >= 768 && width < 992) {
    return "md" // Medium
  } else if (width >= 992 && width < 1200) {
    return "lg" // Large
  } else if (width >= 1200 && width < 1400) {
    return "xl" // Extra large
  } else {
    return "xxl" // Extra extra large
  }
}
