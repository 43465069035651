import { Col, Container, Row } from "react-bootstrap"
import CompaniesFilter from "./CompaniesFilter/CompaniesFilter"
import CompaniesList from "./CompaniesList/CompaniesList"
import { useState } from "react"
import Loading from "../Loading"
import { CompanyI } from "../../common/interface/Companies.interface"
import { useTranslation } from "react-i18next"
import CompaniesSkeletonList from "./Skeletons/CompaniesSkeletonList"

import Skeleton from "react-loading-skeleton"

const CompaniesContainer = () => {
  const { t } = useTranslation()

  const [companies, setCompanies] = useState<CompanyI[] | undefined>([])

  const [loading, setLoading] = useState(false)

  const toggleLoading = (status: boolean) => setLoading(status)

  return (
    <Container className="py-5">
      <Row>
        <Col xs={7} className="mb-5">
          <div>
            <h1 className="text-primary display-1">
              {loading ? (
                <Skeleton width={300} height={60} />
              ) : (
                t("The companies we are partnered with")
              )}
            </h1>
          </div>
          <span className="text-muted fs-2">
            {loading ? (
              <Skeleton width={400} />
            ) : (
              t(
                "People globally are fundraising for the causes they care about deeply."
              )
            )}
          </span>
        </Col>

        <CompaniesFilter
          isLoading={loading}
          setCompanies={setCompanies}
          toggleLoading={toggleLoading}
        >
          {loading ? (
            <CompaniesSkeletonList />
          ) : (
            <>
              <CompaniesList companies={companies} />
            </>
          )}
        </CompaniesFilter>
      </Row>
    </Container>
  )
}

export default CompaniesContainer
