// import ReactCardSlider from "react-card-slider-component"
import { Carousel } from "primereact/carousel"
import { useState } from "react"
import { Modal } from "react-bootstrap"
const CharityPhotos = props => {
  const sliderClick = () => {
    console.log("click")
  }
  const slides = [
    {
      image: "https://picsum.photos/200/300",
      title: "This is a title",
      description: "This is a description",
      clickEvent: sliderClick,
    },
    {
      image: "https://picsum.photos/600/500",
      title: "This is a second title",
      description: "This is a second description",
      clickEvent: sliderClick,
    },
    {
      image: "https://picsum.photos/700/600",
      title: "This is a third title",
      description: "This is a third description",
      clickEvent: sliderClick,
    },
    {
      image: "https://picsum.photos/500/400",
      title: "This is a fourth title",
      description: "This is a fourth description",
      clickEvent: sliderClick,
    },
    {
      image: "https://picsum.photos/200/300",
      title: "This is a fifth title",
      description: "This is a fifth description",
      clickEvent: sliderClick,
    },
    {
      image: "https://picsum.photos/800/700",
      title: "This is a sixth title",
      description: "This is a sixth description",
      clickEvent: sliderClick,
    },
    {
      image: "https://picsum.photos/300/400",
      title: "This is a seventh title",
      description: "This is a seventh description",
      clickEvent: sliderClick,
    },
  ]
  const responsiveOptions = [
    {
      breakpoint: "1400px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: "767px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
      numScroll: 1,
    },
  ]
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [show, setShow] = useState(false)
  const productTemplate = product => {
    return (
      <div
        className="border-1 surface-border border-round m-2 text-center py-5 px-3 clickable"
        onClick={() => {
          setShow(true)
          setSelectedItem(product)
        }}
      >
        <div className="">
          <img
            src={`${product.image}`}
            alt={product.name}
            className="w-6 shadow-2"
            style={{
              height: "200px",
              width: "100%",
              objectFit: "cover",
              borderRadius: "10%",
            }}
          />
        </div>
        {/* <div>
          <h4 className="mb-1">{product.title}</h4>
       
        </div> */}
      </div>
    )
  }
  return (
    <>
      {show && (
        <Modal show={show} onHide={() => setShow(false)} size="lg">
          <Modal.Body className="text-center">
            <img className="img-fluid" src={selectedItem.image} />
          </Modal.Body>
        </Modal>
      )}
      <Carousel
        value={slides}
        numVisible={3}
        numScroll={1}
        responsiveOptions={responsiveOptions}
        className="custom-carousel"
        circular
        autoplayInterval={3000}
        itemTemplate={productTemplate}
      />
    </>
  )
}

export default CharityPhotos
