import { AxiosResponse } from "axios"
import React, { useEffect, useState } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import API from "../../common/API"

const CompanyUpdateStatus = props => {
  const { t } = useTranslation()
  const [records, setRecords] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const bindDataRemote = async () => {
    try {
      const data: AxiosResponse<any> = await API.getAction("companyStatus/list")
      console.log(data)
      setRecords(data.data.response)
    } catch (error) {
      console.error(error)
    }
  }
  const assign = async status => {
    try {
      setIsLoading(true)
      let query = "companyId=" + props.record.id + "&status=" + status.id
      const data: AxiosResponse<any> = await API.getAction(
        "company/updateStatus",
        query
      )
      console.log(data)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
      props.toggle()
    }
  }
  useEffect(() => {
    bindDataRemote()
  }, [])
  return (
    <Modal
      show={true}
      onHide={props.toggle}
      //   size={props.modalSize ? props.modalSize : "lg"}
      //   className={props.cssClass}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Company Status Update")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex gap-2 flex-col px-4">
          {records &&
            records.length > 0 &&
            records.map((item, index) => {
              return (
                <Row key={index}>
                  <Col xs={"auto"}>
                    <i
                      className="fa fa-circle me-2"
                      style={{ color: item.color }}
                    />
                  </Col>
                  <Col>{item.name}</Col>
                  <Col className="text-end">
                    <button
                      className="btn btn-primary btn-sm"
                      disabled={isLoading}
                      onClick={() => assign(item)}
                    >
                      {t("Assign")}
                    </button>
                  </Col>
                  <Col xs={12}>
                    <hr />
                  </Col>
                </Row>
              )
            })}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CompanyUpdateStatus
