interface LogoProps {
  logoName: 'logo1' | 'logo2'; // Add more logos here if you have to.
  alt?: string;
  className?: string;
  width?: string | number;
  height?: string | number;
}

const logos: Record<string, string> = {
  logo1: require('../../assets/img/logo_ibbundance.png'),
  logo2: require('../../assets/img/logo_ibbundance_transparent.png'),
};

const Logo = ({ logoName, alt = 'Logo', className, width = '100px', height = 'auto' }: LogoProps): JSX.Element => {
  return <img src={logos[logoName]} alt={alt} className={className} width={width} height={height} />;
};

export default Logo;
