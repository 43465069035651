import { use } from "i18next"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { parsedUser } from "../../../common/GetCurrentUser"
import { Modal } from "react-bootstrap"
import FieldSelector from "../../core/FieldSelector"
import { useFormik } from "formik"
import * as yup from "yup"
import { toast } from "react-toastify"
import yupTypeValidator from "../../../common/YupTypeValidator"
import CRUDField from "../../core/genericCRUD/CRUDField"
import Sha256 from "../../../common/sha256"
import API from "../../../common/API"

const CompaniesAddEdit = props => {
  const { t } = useTranslation()
  const user = parsedUser()
  const [isLoading, setIsLoading] = useState(false)
  const [countries, setCountries] = useState([])

  const fields = [
    {
      name: "id",
      htmlType: "hidden",
      dbType: "string",
    },
    {
      name: "name",
      htmlType: "text",
      placeHolder: t("What's your company name?"),
      dbType: "string",
      title: t("name") ?? "",
      containerClass: "col-12 col-md-6",
      required: true,
    },

    {
      name: "phone",
      htmlType: "text",
      placeHolder: t("What's your company phone?"),
      dbType: "string",
      title: t("phone") ?? "",
      containerClass: "col-12 col-md-6",
      required: true,
    },
    {
      name: "logo",
      htmlType: "picture",
      dbType: "blob",
      title: t("logo") ?? "",
      typeFile: "image/*",
      containerClass: "col-12",
      required: true,
    },
    {
      name: "ownerId",
      htmlType: "hidden",
      dbType: "string",
      value: user.ID,
    },
    {
      name: "description",
      htmlType: "textarea",
      placeHolder: t("talk about your company"),
      dbType: "string",
      title: t("description") ?? "",
      containerClass: "col-12",
      required: true,
    },
    {
      name: "website",
      htmlType: "text",
      placeHolder: t("Enter your URL website"),
      dbType: "string",
      title: t("website") ?? "",
      containerClass: "col-12",
      required: true,
    },
    {
      name: "countryId",
      htmlType: "select",
      placeHolder: t("Enter your URL website"),
      dbType: "string",
      title: t("Countries") ?? "",
      containerClass: "col-12",
      dataSourceLocal: countries,
      required: true,
    },
  ]
  type YupSchema =
    | yup.StringSchema
    | yup.BooleanSchema
    | yup.NumberSchema
    | yup.DateSchema
  const [formikInitialObject, setFormikInitialObject] = useState<any>({})
  const [validationsSchema, setValidationSchema] =
    useState<yup.ObjectSchema<any>>()
  const formik = useFormik({
    initialValues: formikInitialObject,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: () => addEditRecord(),
  })
  //   Methods
  const fillFormikObject = (record: any) => {
    fields.forEach((item: CRUDField) => {
      if (item && item.name.length > 0) {
        //console.log("field name", item.name, "RecordValue ", record[item.name]);
        if (record && record[item.name]) {
          formik.setFieldValue(item.name, record[item.name])
        }
      }
    })
  }

  const prepareFormikObject = () => {
    const initialObject: { [key: string]: string } = {}
    const initialObjectValidation: { [key: string]: YupSchema } = {}

    if (fields) {
      fields.forEach((item: CRUDField) => {
        // if (item.FieldName === "Id" || item.FieldName === "File") return;

        initialObject[item.name] = item.value

        if (item.required) {
          const schema = yupTypeValidator(item)
          if (schema) {
            initialObjectValidation[item.name] = schema
          }
        }
      })
    }

    // formik.initialValues = initialObject;
    setFormikInitialObject(initialObject)
    setValidationSchema(yup.object().shape(initialObjectValidation))
  }
  const addEditRecord = async () => {
    try {
      let query = formik.values

      //Now let's go over all the fields to check if there's a password
      fields.forEach((item: CRUDField) => {
        if (item.htmlType === "password") {
          if (query[item.name] && query[item.name].length > 0) {
            let password = Sha256.hash(query[item.name])
            query[item.name] = password
          }
        }
      })

      //   if (props.parentId) {
      //     query[props.parentId] = props.parentValue
      //   }

      console.log(query)
      setIsLoading(true)
      let request = (await API.postAction("company/AddEdit", query)) as any
      if (!query?.id && props.childrens) {
        let response = request.response
        fillFormikObject(response)
      } else {
        props.toggle(null)

        toast.success(t("Record saved"))
      }
    } catch (ex: any) {
      console.error(ex)

      toast.error(ex)
    } finally {
      setIsLoading(false)
    }
  }
  const updateField = (fieldName: string, fieldValue: any) => {
    console.log(fieldName, fieldValue)
    formik.setFieldValue(fieldName, fieldValue)
  }

  const bindDataRemote = async () => {
    let request = await API.getAction(
      "company/details",
      "id=" + props.record.id + "&dontCount=true"
    )
    if (request.data.status === "ok") {
      fillFormikObject(request.data.response)
    }
  }

  const bindDataCountries = async () => {
    try {
      let filterFields = `name=${""}&`
      let queryString = `${filterFields}Quantity=${255}&Page=${0}`

      let requestAPI = await API.getAction("Country/list?" + queryString)

      if (requestAPI.status === 200) {
        setCountries(requestAPI.data.response)
      } else {
        throw new Error("Oops, thats a error?")
      }
    } catch (error) {
      toast.error(`${error}`)
    }
  }
  useEffect(() => {
    bindDataCountries()
  }, [])

  //Event
  useEffect(() => {
    prepareFormikObject()
  }, [])

  useEffect(() => {
    if (props.record.id) {
      bindDataRemote()
    }
  }, [props.record.id])

  return (
    <Modal
      show={true}
      onHide={props.toggle}
      //   size={props.modalSize ? props.modalSize : "lg"}
      //   className={props.cssClass}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("Company")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex gap-2 flex-col px-4">
          <div className="row">
            {fields &&
              fields
                .filter(x => x.htmlType !== "hidden")
                .map((item, index) => {
                  return (
                    <FieldSelector
                      key={index}
                      model={item}
                      updateField={updateField}
                      obj={formik.values}
                      value={
                        formik.values[item.name as keyof typeof formik.values]
                      }
                      errors={formik.errors}
                    />
                  )
                })}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        {/* {formik.errors? <div className="text-red-600">{console.log(formik.errors)}</div> :null} */}
        <div className="modal-action m-0 p-2 ">
          {isLoading && (
            <div className="me-4 d-inline-block">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <button
            className="btn btn-secondary me-2"
            disabled={isLoading}
            onClick={() => props.toggle()}
            type="button"
          >
            {t("Cancel")}
          </button>
          {/* if there is a button in form, it will close the modal */}
          <button
            className="btn btn-primary text-white"
            disabled={isLoading}
            onClick={() => formik.submitForm()}
            type="button"
          >
            {t("Save")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default CompaniesAddEdit
