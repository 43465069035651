import React, { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import CRUD from "../core/genericCRUD/CRUD"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import { Card, Container } from "react-bootstrap"
import Pagination from "../core/Pagination"
import { Routes } from "../../routes"
import { confirmAlert } from "react-confirm-alert"
import ActionBar from "../core/ActionBar"
import Filter from "../core/Filter/Filter"
import CompaniesAddEdit from "../CompaniesComponents/CompaniesAddEdit/CompaniesAddEdit"
import { useHistory } from "react-router"
import CompanyUpdateStatus from "../CompaniesComponents/CompanyUpdateStatus"

const AdminOrganizationsComponent = props => {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(props.pageSize ?? 10)
  const [totalPages, setTotalPages] = useState(1)
  const user = parsedUser()
  const [record, setRecord] = useState<any>({})
  const [records, setRecords] = useState<any>([])
  const [showAddEdit, setShowAddEdit] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [filterObj, setFilterObj] = useState({})
  const [showStatus, setShowStatus] = useState(false)
  const history = useHistory()
  // METHODS

  const toggleFilter = () => setShowFilter(!showFilter)
  const onPageChanged = (obj: number) => {
    setPage(obj)
  }
  const bindDataRemote = async () => {
    try {
      const request = await API.getAction(`company/list`)

      if (request.data.status === "ok") {
        setRecords(request.data.response)

        setTotalPages(request.data.quantityOfPages)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const toggleAddEdit = (item: any) => {
    setRecord(item)
    setShowAddEdit(!showAddEdit)
    if (showAddEdit) {
      bindDataRemote()
    }
  }

  const deleteRecord = async (item: any) => {
    if (item) {
      confirmAlert({
        closeOnClickOutside: false,
        message: t("Are you sure you want to delete this record?") ?? "",
        buttons: [
          {
            label: t("Yes"),
            onClick: async () => {
              await API.getAction("company/delete" + "?id=" + item.id)
              await bindDataRemote()
              setRecord(null)
            },
          },
          {
            label: "No",
            onClick: () => {
              console.log("no")
            },
          },
        ],
      })
    }
  }

  const getFilterValues = obj => {
    console.log("obj", obj)
    setFilterObj(obj)
  }
  const toggleStatus = () => {
    setShowStatus(!showStatus)
    if (showStatus) {
      bindDataRemote()
    }
  }
  //  EVENTS
  useEffect(() => {
    bindDataRemote()
  }, [])
  // UI
  return (
    <>
      {showStatus && (
        <CompanyUpdateStatus toggle={() => toggleStatus()} record={record} />
      )}
      {showAddEdit && (
        <CompaniesAddEdit toggle={toggleAddEdit} record={record} />
      )}
      <Container>
        <ActionBar
          headerStyle={props.headerStyle}
          title={t("Companies")}
          icon={""}
          actions={
            <>
              <button
                className="btn btn-primary btn-sm me-2"
                onClick={() => {
                  toggleAddEdit(null)
                }}
              >
                <i className="fa fa-plus " />
              </button>

              <button
                className={
                  "btn  btn-sm  " + (showFilter ? "btn-secondary" : "")
                }
                onClick={() => toggleFilter()}
              >
                <i className="fa fa-filter" />
              </button>
            </>
          }
        ></ActionBar>

        {showFilter && (
          <div className="my-4">
            <Filter
              toggleFilter={toggleFilter}
              fields={[
                {
                  name: "name",
                  htmlType: "text",
                  dbType: "string",
                  title: t("Name") ?? "",
                  containerClass: "col-12 col-md-4",
                  required: true,
                },
              ]}
              filterAction={() => {
                bindDataRemote()
              }}
              setFilterFields={getFilterValues}
            />
          </div>
        )}
        <Card className="mt-4">
          <Card.Body className="p-0">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{t("Name")}</th>
                  <th>{t("creator")}</th>
                  <th>{t("Email")}</th>
                  <th>{t("Status")}</th>
                  <th>{t("Visits")}</th>
                  <th>{t("Actions")}</th>
                </tr>
              </thead>
              <tbody>
                {records &&
                  records.length > 0 &&
                  records.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.ownerName}</td>
                      <td>{item.ownerEmail}</td>
                      <td>
                        {item.companyStatus && (
                          <div
                            className="clickable"
                            onClick={() => {
                              toggleStatus()
                              setRecord(item)
                            }}
                          >
                            <span
                              className={`badge text-dark`}
                              style={{
                                backgroundColor: item.companyStatus.color,
                              }}
                            >
                              {item.companyStatus.name}
                            </span>
                            <span className="ms-2">
                              <i className="fa fa-chevron-down"></i>
                            </span>
                          </div>
                        )}
                      </td>
                      <td>{item.visits ?? "-"}</td>

                      <td className="text-end">
                        <div className="btn-group">
                          <button
                            onClick={() => {
                              history.push(
                                Routes.OrganizationDetails.clean + item.id
                              )
                            }}
                            //href={Routes.OrganizationDetails.clean + item.id}
                            className="btn  btn-sm"
                          >
                            <i className="fas fa-eye"></i>
                          </button>
                          <button
                            onClick={() => {
                              toggleAddEdit(item)
                            }}
                            //href={Routes.OrganizationDetails.clean + item.id}
                            className="btn  btn-sm"
                          >
                            <i className="fas fa-edit"></i>
                          </button>
                          <button
                            onClick={() => {
                              deleteRecord(item)
                            }}
                            className="btn   btn-sm"
                          >
                            <i className="fas text-danger fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>

        <Card className="mt-4">
          <Card.Body className="p-0 pt-2">
            <Pagination
              totalPages={totalPages}
              currentPage={page}
              onPageChanged={onPageChanged}
            />
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}

export default AdminOrganizationsComponent
