import React, { FC } from "react"
import { notificationI } from "../../../common/interface/Notification.interface"
import { Col, Row } from "react-bootstrap"

const NotificationItem: FC<notificationI> = ({
  typeNotification,
  content,
  createdAt,
  name,
}) => {
  const byTypeNotification = (typeNotification: string) => {
    switch (typeNotification) {
      case "donation":
        return {
          bgColor: "#F9F4FF",
          icon: "fa-solid fa-check",
          colorIcon: "#370070",
        }
      case "approved":
        return {
          bgColor: "#FFF9FC",
          icon: "fa-solid fa-exclamation",
          colorIcon: "#EB4A97",
        }
      case "reject":
        return {
          bgColor: "#FFF2F2",
          icon: "fa-solid fa-xmark",
          colorIcon: "#FF6568",
        }
      case "update":
        return {
          bgColor: "#FFFFFF",
          icon: "fa-solid fa-pen",
          colorIcon: "#FF9F43",
        }
      case "message":
        return {
          bgColor: "#FFFFF",
          icon: "fa-solid fa-envelope",
          colorIcon: "#8B2AF5",
        }
      default:
        return {
          bgColor: "#FFFFFF",
          icon: "fa-solid fa-circle-check",
          colorIcon: "#370070",
        }
    }
  }

  return (
    <Row
      className="w-100 my-2 py-4 notificationSelected"
      style={{
        backgroundColor: byTypeNotification(typeNotification).bgColor,
        borderRadius: "16px",
      }}
    >
      <Col xs={2} className="d-flex align-items-center justify-content-center">
        <div
          className="d-flex align-items-center justify-content-center rounded-circle"
          style={{
            width: "3.125rem",
            height: "3.125rem",
            backgroundColor: byTypeNotification(typeNotification).colorIcon,
          }}
        >
          <i
            className={`${
              byTypeNotification(typeNotification).icon
            } fs-3 text-white`}
            /* style={{ color: byTypeNotification(typeNotification).colorIcon }} */
          />
        </div>
      </Col>
      <Col xs={10}>
        <div className="d-flex flex-column ">
          <div>
            <span className="poppins-medium text-primary">{name}</span>,{" "}
            <small className="text-muted poppins-regular ">{content}</small>
          </div>
          <div>
            <small className="text-muted poppins-regular ">{createdAt}</small>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default NotificationItem
