import React, { FC } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { notificationI } from "../../../common/interface/Notification.interface"
import NotificationItem from "./NotificationItem"
import { useTranslation } from "react-i18next"

interface NotificationsModalProps {
  show: boolean
  toggle: () => void
}

const NotificationsModal: FC<NotificationsModalProps> = ({ show, toggle }) => {
  const { t } = useTranslation()

  const exampleNotifications: notificationI[] = [
    {
      typeNotification: "donation",
      name: "How to create new donations",
      content: "Message here.",
      createdAt: "12:38",
    },
    {
      typeNotification: "approved",
      name: "Dangers of mismanagement",
      content: "Message here.",
      createdAt: "12:38",
    },
    {
      typeNotification: "approved",
      name: "Dangers of mismanagement",
      content: "Message here.",
      createdAt: "12:38",
    },
    {
      typeNotification: "approved",
      name: "Dangers of mismanagement",
      content: "Message here.",
      createdAt: "12:38",
    },
    {
      typeNotification: "approved",
      name: "Dangers of mismanagement",
      content: "Message here.",
      createdAt: "12:38",
    },
    {
      typeNotification: "reject",
      name: "Frederick has sent you a new message",
      content: "Message here.",
      createdAt: "12:38",
    },
    {
      typeNotification: "update",
      name: "Frederick has sent you a new message",
      content: "Message here.",
      createdAt: "12:38",
    },
    {
      typeNotification: "message",
      name: "Frederick has sent you a new message",
      content: "Message here.",
      createdAt: "12:38",
    },
    {
      typeNotification: "message",
      name: "Frederick has sent you a new message",
      content: "Message here.",
      createdAt: "12:38",
    },
    {
      typeNotification: "message",
      name: "Frederick has sent you a new message",
      content: "Message here.",
      createdAt: "12:38",
    },
  ]
  return (
    <Modal show={show} onHide={toggle} className="modalRight ">
      {/*  <Modal.Header closeButton>Modal</Modal.Header> */}
      <Modal.Body className="bg-white">
        <Row className="m-3">
          <Col xs={12} className="d-flex justify-content-between">
            <h3 className="poppins-medium text-primary">
              {t("Notifications")}
            </h3>
            <button className="btn" onClick={toggle}>
              <i className="fa-solid fa-xmark fs-3" />
            </button>
          </Col>

          {exampleNotifications.map((item: notificationI, index) => {
            return (
              <Col xs={12} key={index}>
                <NotificationItem
                  content={item.content}
                  createdAt={item.createdAt}
                  name={item.name}
                  typeNotification={item.typeNotification}
                />
              </Col>
            )
          })}
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default NotificationsModal
