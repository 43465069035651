import React from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"

import { useTranslation } from "react-i18next"
import slide4 from "../../assets/img/slide4.jpg"
import slide from "../../assets/img/slide.jpg"
import slide9 from "../../assets/img/slide9.jpg"
import slide11 from "../../assets/img/slide11.jpg"
import slide6 from "../../assets/img/slide6.jpg"
const AboutContainer = () => {
  const history = useHistory()

  const { t } = useTranslation()

  const handleNavigate = route => {
    history.push(route)
  }

  return (
    <div className="w-100 py-5">
      <header className="mb-4">
        <h1 className="display-2 text-primary">
          {t(`The power of giving & The blessings of receiving`)}
        </h1>
      </header>
      <Row className="flex-column-reverse flex-md-row mb-4 mb-md-2 w-100">
        <Col md={6} xs={12} className="mb-4">
          <p className="text-lg mb-4 mb-md-2">
            {t(
              `The Abundance Application embraces the belief that there's more than enough for everyone, where opportunities, resources, and success are not limited but instead, abundant.`
            )}
          </p>
          <div className="d-grid d-md-block">
            <Button
              variant="primary"
              onClick={() => handleNavigate("/signin")}
              size="lg"
            >
              {t("Join now")}
            </Button>
          </div>
        </Col>
        <Col md={6} xs={12} className="mb-4 mb-md-2 text-end">
          <img
            src={`${slide}`}
            alt="placeholder"
            className="rounded-4 shadow-sm"
          />
        </Col>
      </Row>
      <Row className="mb-4 w-100">
        <Col md={12} className="mb-2">
          <h2 className="display-3">
            {t(`The three keys to an abundance mindset are:`)}
          </h2>
          <hr />
        </Col>
        <Col md={4} xs={12} className="mb-4 mb-md-2">
          <h3>{t(`Believing in Abundance:`)}</h3>
          <p>
            {t(
              "Trusting that there are ample opportunities and resources for everyone."
            )}
          </p>
        </Col>
        <Col md={4} xs={12} className="mb-4 mb-md-2">
          <h3>{t(`Being Grateful:`)}</h3>
          <p>
            {t(
              "Recognizing and appreciating the good in our lives, which attracts more positivity."
            )}
          </p>
        </Col>
        <Col md={4} xs={12} className="mb-4 mb-md-2">
          <h3>{t(`Walking in Your Purpose:`)}</h3>
          <p>
            {t(
              "Aligning actions with one's true calling, leading to a more fulfilling and impactful life."
            )}
          </p>
        </Col>
        <Col xs={12}>
          <p>
            {t(` These three principles can open the doors not only to your vision
            but to many benefits you have yet to see. This dynamic application
            is the key to giving you the opportunity to give and receive.`)}
          </p>

          <p>
            {t(`Giving
            and receiving are two aspects of the same flow of energy in the
            universe. It's important to be both a good giver and a good
            receiver. Giving can inspire positive change and healing in a world
            so in need of it. The Attitudinal Healing definition of giving and
            receiving comes from an egoless place.`)}
          </p>
        </Col>
      </Row>
      <Row className="mb-2 w-100">
        <Col md={12} className="mb-2">
          <h2 className="display-3">{t("IBBUNDANCE")}</h2>
          <hr />
        </Col>
        <Col md={5} xs={12} className="mb-4 mb-md-2">
          <img src={slide6} alt="placeholder" className="rounded-4 shadow-sm" />
        </Col>
        <Col md={7} xs={12} className="mb-4 mb-md-2">
          <p>
            {t(
              `IBBUNDANCE is designed to revolutionize the way companies, sponsors, and foundations interact and connect with each other as givers and receivers of great abundance. This connection will offer all three the opportunity to contribute in a world often driven by individualistic pursuits, providing a transformative path towards abundance and fulfillment. It's about fostering a positive flow of energy that enriches both the giver and the receiver.`
            )}
          </p>
          <p>
            {t(
              `Are you ready to unlock the hidden potential of this timeless principle and experience the boundless joy it brings? Embrace IBBUNDANCE—the power of giving and the blessings of receiving!`
            )}
          </p>
        </Col>
      </Row>

      <Row className="my-4 w-100">
        <Col md={4} xs={12} className="mb-4 mb-md-2">
          <Link to={"/About"}>
            <Card>
              <Card.Img src={slide11} alt="placeholder" className="mb-4" />
              <Card.Body>
                <Card.Title className="text-center">
                  {t(`The Power of Giving:`)}
                </Card.Title>
                <p>
                  {t(
                    `Giving is a powerful expression of compassion, empathy, and generosity. It transcends boundaries and fosters connections, creating ripple effects of positivity that extend far beyond the initial act. Whether it's giving our time, expertise, or resources, each act of kindness has the potential to make a meaningful difference in someone else's life and bring joy and fulfillment to our own.`
                  )}
                </p>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4} xs={12} className="mb-4 mb-md-2">
          <Link to={"/About"}>
            <Card>
              <Card.Img src={`${slide4}`} alt="placeholder" className="mb-4" />
              <Card.Body>
                <Card.Title className="text-center">
                  {t(`The Blessings of Receiving:`)}
                </Card.Title>
                <p>
                  {t(
                    `On the other hand, receiving with gratitude allows us to fully appreciate the abundance that surrounds us. It's about being open to the gifts that life presents us, whether it's opportunities, love, or support from others. By embracing the blessings of receiving, we acknowledge our interconnectedness with the universe and invite even more abundance into our lives.`
                  )}
                </p>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={4} xs={12} className="mb-4 mb-md-2">
          <Link to={"/About"}>
            <Card>
              <Card.Img src={slide9} alt="placeholder" className="mb-4" />
              <Card.Body>
                <Card.Title className="text-center">
                  {t(`Creating a Positive Flow of Abundance:`)}
                </Card.Title>
                <p>
                  {t(
                    `When we cultivate a mindset of giving and receiving, we create a positive flow of abundance that enriches every aspect of our lives. It's a virtuous cycle where generosity begets generosity, and blessings multiply exponentially. By actively participating in this flow, we not only experience greater fulfillment and happiness but also contribute to the collective well-being of humanity.`
                  )}
                </p>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={12} className="pt-4 text-center mb-4">
          <b>
            {t(
              `True abundance is not measured by what we possess but by what we give and receive with an open heart.`
            )}
          </b>
        </Col>
      </Row>
      <Row className="mb-4 w-100">
        <Col md={12}>
          <h2 className="display-3">{t("Vision & Mission")}</h2>
          <hr />
          <p>
            {t(
              `In a world often driven by scarcity and competition, the concept of an abundant mindset emerges as a refreshing and transformative approach to life. In our quest for success and happiness, we often overlook the simple yet profound truth that giving and receiving are intricately connected aspects of the human experience. Whether it's a kind gesture, a helping hand, or a heartfelt gift, the act of giving not only benefits others but also nourishes our own soul. Similarly, being open to receiving allows us to welcome the abundance that life has to offer and amplifies the blessings we receive.`
            )}
          </p>
        </Col>
      </Row>
    </div>
  )
}

// <Link to={'/foundations'}>Click here</Link>

export default AboutContainer
