import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import API from "../../common/API"
import Settings from "../../common/Settings"
interface ProfileMyFoundationsProps {
  userId: string
}
const ProfileMyFoundations = (props: ProfileMyFoundationsProps) => {
  const { t } = useTranslation()
  const [records, setRecords] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const requestRemote = async () => {
    try {
      setIsLoading(true)
      const request = await API.getAction(
        "charity/list?ownerId=" + props.userId
      )
      if (request.data.status === "ok") {
        console.log(request.data.response)
        setRecords(request.data.response)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (props.userId) {
      requestRemote()
    }
  }, [props.userId])

  return (
    <>
      <div className="text-center">
        <button className="btn btn-primary btn-sm my-4" type="button">
          {t("Create Foundation")}
        </button>
      </div>
      {isLoading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">{t("Loading")}...</span>
          </div>
        </div>
      ) : records && records.length > 0 ? (
        records.map((item: any, index: number) => {
          return (
            <div className="card mb-3 bg-light shadow-lg" key={index}>
              <div className="row no-gutters">
                <div className="col-md-4">
                  <img
                    src={`${Settings.BasePath}files/${item.logo}`}
                    className="card-img"
                    alt="..."
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-header">
                    <h5 className="card-title d-inline">{item.name}</h5>
                    <button className="btn text-primary btn-sm ms-2">
                      <i className="fa fa-edit"></i>
                    </button>
                    <button className="btn btn-sm text-danger ms-2">
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                  <div className="card-body">
                    <p className="card-text">{item.description}</p>
                    <p className="card-text">
                      <small className="text-muted">
                        {/* {moment(item.created)} */}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <div className="text-center">{t("No Records")}</div>
      )}
    </>
  )
}

export default ProfileMyFoundations
